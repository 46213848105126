import { ReactNode } from "react";
import TopLogoSection from "./TopLogoSection";
import Header from "./Header";
import Content from "./Content"
import Footer from "./Footer"
 
interface BaseLayoutProps {
  children: ReactNode;
}

export default function BaseLayout({ children }: BaseLayoutProps): JSX.Element {
  return (
    <div>
      <TopLogoSection/>
      <Header />
      <Content>{children}</Content>
      <Footer />
    </div>
  );
}
