import { useGetPeriodo } from "hooks/useRequest";

import { Mutation } from "hooks/useMutation";
import { editarPeriodo, eliminarGrupo } from "./mutation";
import { eliminarPeriodoMatricula } from "../Admin/mutations";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";

export const usePeriodo = () => {
    const history = useHistory();

    const mutation = Mutation(editarPeriodo);
    const deleteGroupMutation = Mutation(eliminarGrupo);
    const deletePeriodoMutaton = Mutation(eliminarPeriodoMatricula);

    const { search } = useLocation();
    const { periodo } = queryString.parse(search);
    const [isVisible, setIsVisible] = useState(false);
    const [isUpdateVisible, setUpdateVisible] = useState(false);
    const [isDeletePeriodoVisible, setIsDeletePeriodoVisible] = useState(false);

    const [selectedGroup, setSelectedGroup] = useState(null);
    const { data, isLoading, isSuccess, refetch, remove, isRefetching } =
        useGetPeriodo({
            //@ts-ignore
            id: parseInt(periodo),
        });

    useEffect(() => {
        if (deleteGroupMutation.isSuccess) {
            refetch();
        }
    }, [deleteGroupMutation.isSuccess, refetch]);

    useEffect(() => {
        if (mutation.isSuccess) {
            refetch();
        }
    }, [mutation.isSuccess, refetch]);

    const initialValues =
        !isLoading && isSuccess
            ? {
                name: data.nombre,
                type: parseInt(data.tipoPeriodo.id),
                consultationDate: moment(data.fechaConsulta),
                startDate: moment(data.fechaInicio),
                endDate: moment(data.fechaFin),
            }
            : null;

    const handleOnCreateGrupo = (values: any) => {
        history.push({
            pathname: `/admin/crear-grupo/`,
            search: `?periodo=${periodo}`,
        });
    };

    const handleOnCreatePeriod = (values: any) => {
        const endDate =
            values.type === 1
                ? moment(values.startDate, "YYYY-MM-DD")
                    .add(2, "days")
                    .format("YYYY-MM-DD")
                : moment(values.endDate).format("YYYY-MM-DD");

        const formattedValues = {
            //@ts-ignore
            id: parseInt(periodo),
            nombre: values.name,
            tipoPeriodo: values.type,
            fechaInicio: moment(values.startDate).format("YYYY-MM-DD"),
            fechaFin: endDate,
            fechaConsulta: moment(values.consultationDate).format("YYYY-MM-DD"),
        };
        /*@ts-ignore*/
        mutation.mutate(formattedValues);
    };

    const handleOnDeleteModalVisible = (value: any) => {
        setIsVisible(true);
        setSelectedGroup(value);
    };

    const handleOnDeleteGroup = () => {
        /*@ts-ignore*/
        deleteGroupMutation.mutate({ id: selectedGroup.id });
        setIsVisible(false);
        refetch();
    };

    const handleOnDeletePeriod = () => {
        /*@ts-ignore*/
        deletePeriodoMutaton.mutate({ id: periodo });
        setIsDeletePeriodoVisible(false);
        history.push({
            pathname: `/admin/`,
        });
    };

    const handleCancel = () => {
        setIsDeletePeriodoVisible(false);
        refetch();
    };

    return {
        data,
        initialValues,
        selectedGroup,
        periodo,
        isLoading,
        isLoadingCreatePeriod: mutation.isLoading,
        isRefetching,
        isVisible,
        isUpdateVisible,
        isDeletePeriodoVisible,
        setUpdateVisible,
        setIsDeletePeriodoVisible,
        setIsVisible,
        handleCancel,
        handleOnDeletePeriod,
        handleOnCreateGrupo,
        handleOnDeleteModalVisible,
        handleOnDeleteGroup,
        handleOnCreatePeriod,
        remove
    }

}