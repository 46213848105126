import styled from "styled-components";
import { Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

interface TitletProps {
  title?: string;
  isLoading: boolean;
}

export const Spinner = ({
  title = "Cargando",
  isLoading,
}: TitletProps): JSX.Element => {
  return (
    <SpinnerContainer isLoading={isLoading}>
      {isLoading && (
        <Spin
          spinning={isLoading}
          indicator={
            <LoadingOutlined style={{ color: "#FDB912", fontSize: "70px" }} />
          }
        >
          {/*<Body1 style={{ marginBottom: "24px" }}>{title}</Body1>*/}
        </Spin>
      )}
    </SpinnerContainer>
  );
}

const SpinnerContainer = styled.div<TitletProps>`
  display: ${props => props.isLoading ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
