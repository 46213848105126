import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { BackButton, Title, HeaderContainer } from "components";
import { MatricularDocenteForm } from "components/Forms";
import { crearDocente } from "./mutation";
import { Mutation } from "hooks/useMutation";
import { useGetDocente, useGetPeriodo } from "hooks/useRequest";

export function EditarDocente() {
  const history = useHistory();
  const { search } = useLocation();
  const mutation = Mutation(crearDocente);

  const { docente, periodo, grupo } = queryString.parse(search);

  const { data } = useGetDocente({
    //@ts-ignore
    id: parseInt(docente),
  });

  const { data: dataPeriodo } = useGetPeriodo({
    //@ts-ignore
    id: parseInt(periodo),
  });

  const initialValues = data
    ? {
        name: data.nombre,
        nombramiento: data.condicion,
        mail: data.correoInstitucional,
        secondaryMail: data.correoSecundario,
        id: data.identificacion,
        tipoId: data.tipo,
        carrera: data.carrera,
        unidad: data.unidad,
        comprobante: null,
        phone: data.telefono,
        periodoMatricula: periodo,
        sede: 1,
      }
    : null;

  const onFinish = (values: any) => {
    const formattedValues = {
      //@ts-ignore
      nombre: values.name,
      condicion: values.nombramiento,
      correoInstitucional: values.mail,
      correoSecundario: values.secondaryMail,
      identificacion: values.id,
      tipo: values.tipoId,
      carrera: values.carrera,
      unidad: values.unidad,
      comprobante: values.comprobante.file,
      telefono: values.phone,
      periodoMatricula: periodo,
      sede: 1,
      id: docente,
    };
    /*@ts-ignore*/
    mutation.mutate(formattedValues);
    //refetch();
  };

  const goBack = () => {
    history.push({
      pathname: "/admin/lista-matriculados",
      search: `?grupo=${grupo}&periodo=${periodo}`,
    });
  };

  return (
    <PageContainer>
      <HeaderContainer>
        <BackButton
          text="Lista de Matriculados"
          link={`/admin/lista-matriculados?grupo=${grupo}&periodo=${periodo}`}
        />
      </HeaderContainer>

      <Title title="Editar Docente" />
      {data && (
        <MatricularDocenteForm
          submitButtonText={"Actualizar"}
          onFinish={onFinish}
          initialValues={initialValues}
          onCancel={() => {
            goBack();
          }}
          tipoPeriodo={dataPeriodo?.tipoPeriodo.id}
        />
      )}
    </PageContainer>
  );
}

const PageContainer = styled.div`
  @media (min-width: 768px) {
    margin-bottom: 45px;
    padding-right: 86px;
    padding-left: 86px;
  }
`;
