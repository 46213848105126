import styled from "styled-components";
import { ReactComponent as DedunLogo } from "./dedun.svg";
import { ReactComponent as Underline } from "./underline.svg";
import { ReactComponent as Departamento } from "./departamento.svg";
import { ReactComponent as Docencia } from "./docencia.svg";

export default function DedunBlack(): JSX.Element {
  return (
    <Container>
      <a target="_blank" href="http://matriculadedun.ucr.ac.cr/" rel="noreferrer">
        <Logo>
          <DedunLogo />
          <Underline />
        </Logo>
        </a>
        <Logo>
          <Departamento />
          <Docencia />
        </Logo>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  &:nth-of-type(1) {
    svg {
      margin-bottom: 5px;
    }
  }
  &:nth-of-type(2) {
    svg {
      margin-bottom: 3px;
    }
  }
  @media (min-width: 768px) {
    &:nth-of-type(1) {
      margin-right: 20px;
      svg {
        margin-bottom: 8px;
        &:nth-of-type(2) {
          margin-bottom: 0;
        }
      }
    }
    &:nth-of-type(2) {
      svg {
        margin-bottom: 5px;
        &:nth-of-type(2) {
          margin-bottom: 0;
        }
      }
    }
  }
`;
