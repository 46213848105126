import styled from "styled-components";
import { Form, Button, Input } from "library";
import { Heading1 } from "components/Typography";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { login } from "./mutation";
import { Mutation } from "hooks/useMutation";

export default function Login() {
  const [email, setEmail] = useState(null);
  const [pass, setPass] = useState(null);
  const [error, setError] = useState(false);
  const history = useHistory();

  const mutation = Mutation(login);

  const OnFinish = async (values: any) => {
    setEmail(values.email);
    setPass(values.password);

    /*@ts-ignore*/
    if (email && pass) {
      /*@ts-ignore*/
      mutation.mutate({
        email: email,
        password: pass
      })
    }
  };

  useEffect(() => {
    if (mutation.isSuccess && mutation.data.data) { 
      const isLoginSuccess  = mutation.data.data.tokenAuth.success
      const token = mutation.data.data.tokenAuth.token
      if(isLoginSuccess){
        localStorage.setItem("AUTH_TOKEN", token);
  
        history.push({
          pathname: '/admin'
        })
      }else{
        setError(true)
      }
    }
  }, [mutation, history]);

  
  return (
    <LoginContainer>
      <TitleContainer>
        <Heading1>Sistema de Gestión Matrícula DEDUN</Heading1>
      </TitleContainer>
      <Form layout={"vertical"} onFinish={(values)=>OnFinish(values)}>
        <FormContainer>
          <UserInputContainer>
            <Form.Item label="Usuario" name="email">
              <Input onChange={(event:any)=>setEmail(event.target.value)} />
            </Form.Item>
          </UserInputContainer>
          <PasswordInputContainer>
            <Form.Item label="Contraseña" name="password">
            <Input type="password" onChange={(event:any)=>setPass(event.target.value)} />
            </Form.Item>
          </PasswordInputContainer>
          {/*<Button variant="link" width="100%">
            ¿Olvidó su contraseña?
  </Button>*/}
        </FormContainer>
        {error && (
          <ErrorContainer>
            <p>
            Usuario o contraseña incorrecto. Por favor revise los datos ingresados.
            </p>
          </ErrorContainer>
        )}

        <Button htmlType="submit" loading={mutation.isLoading} disabled={mutation.isLoading} width="100%">Ingresar</Button>
      </Form>
    </LoginContainer>
  );
}

const LoginContainer = styled.div`
  max-width: 336px;
  margin: 24px auto 32px auto;
  @media (min-width: 768px) {
    max-width: 500px;
    margin: 32px auto;
  }
  @media (min-width: 1200px) {
    max-width: 992px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 126px;
    grid-row-gap: 0px;
    margin: 83px auto 112px auto;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 32px;
`;

const FormContainer = styled.div`
  margin-bottom: 110px;
  label {
    font-size: 12px;
    line-height: 19px;
    font-weight: bold;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 22px;
    }
    @media (min-width: 1200px) {
      line-height: 26px;
    }
  }
  @media (min-width: 1200px) {
    margin-bottom: 48px;
  }
  button {
    text-align: right;
  }
  input {
    display: block;
    width: 100%;
  }
  .ant-form-item-control-input {
    width: 100%;
  }
`;

const UserInputContainer = styled.div`
  margin-bottom: 32px;
`;

const PasswordInputContainer = styled.div`
  margin-bottom: 12px;
  @media (min-width: 1200px) {
    margin-bottom: 16px;
  }
`;

const ErrorContainer = styled.div`
  margin-top: -32px;
  margin-bottom: 20px;
  p {
    color: ${(props) => props.theme.colors.red};
  }
`;
