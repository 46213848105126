import { Body3, Heading1} from "components/Typography";
import styled from "styled-components";
import {humanLegibleDate} from 'utilites/dateFormat'

interface UpcomingProps {
  title: string;
  fechaFin: any;
}

export default function Available({
  title,
  fechaFin
}: UpcomingProps) {

const humanRedableEndDate = humanLegibleDate(fechaFin);

return (
    <div>
       <Heading1>{title}</Heading1>
        <StyledDate>
          <Body3>
            Recuerde matricular antes del <strong>{humanRedableEndDate} a las 5:00
            pm.</strong>
          </Body3>
        </StyledDate>
    </div>
  );
}

const StyledDate = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
`;
