import { DefaultTheme } from 'styled-components'

const baseSize = 32
const baseSpacing = 16

export type ThemeColors =
  | 'admiralBlack'
  | 'blueGraySoft'
  | 'blueGrayHard'
  | 'blueGrayHardText'
  | 'graySoft'
  | 'grayHard'
  | 'cleanWhite'
  | 'admiralDarkBlue'
  | 'admiralBlue'
  | 'skyBlue'
  | 'coralRed'
  | 'mintGreen'
  | 'apolloYellow'
  | 'asterPurple'
  | 'spaceGray'
  | 'faintGray'
  | 'mediumGray'
  | 'softGray'
  | 'lightGray'
  | 'darkBlue'
  | 'lightGray'
  | 'softGray'
  | 'blue'
  | 'gray'

export type ThemeSpacings = 'xxs' | 'xs' | 's' | 'm' | 'l'

export type ThemeSizes = 'xs' | 's' | 'm' | 'l' | 'xl'

export type ThemeFontSizes =
  | 'headingLarge'
  | 'heading'
  | 'headingSmall'
  | 'extraLarge'
  | 'large'
  | 'baseSize'
  | 'small'
  | 'tiny'
  | 'extraTiny'

export type ThemeShadows = 'card' | 'modal' | 'toast'

interface MediaQueryConfig {
  vertical?: boolean
}

export interface CustomTheme {
  borderRadius: string

  larger: (breakpoint: string, config?: MediaQueryConfig) => string
  smaller: (breakpoint: string, config?: MediaQueryConfig) => string
  between: (breakpointMin: string, breakpointMax: string, config?: MediaQueryConfig) => string

  breakpoints: {
    s: string
    m: string
    l: string
  }

  spacings: Record<ThemeSpacings, string>
  sizes: Record<ThemeSizes, string>
  fonts: Record<ThemeFontSizes, string>
  colors: Record<ThemeColors, string>
  shadows: Record<ThemeShadows, string>
}

export const defaultTheme: DefaultTheme = {
  borderRadius: '8px',

  larger: (breakpoint: string, { vertical = false } = {}) =>
    `@media (min-${vertical ? 'height' : 'width'}: calc(${breakpoint} + 0.02px))`,

  smaller: (breakpoint: string, { vertical = false } = {}) =>
    `@media (max-${vertical ? 'height' : 'width'}: ${breakpoint})`,

  between: (breakpointMin: string, breakpointMax: string, { vertical = false } = {}) =>
    `@media (max-${vertical ? 'height' : 'width'}: ${breakpointMax}) and (min-${
      vertical ? 'height' : 'width'
    }: calc(${breakpointMin} + 0.02px))`,

  breakpoints: {
    sm: '768px',
    lg: '1200px',
    xl: '1400px',
  },

  spacings: {
    xxs: `${baseSpacing / 8}px`, // 2px
    xs: `${baseSpacing / 4}px`, // 4px
    s: `${baseSpacing / 2}px`, // 8px
    m: `${baseSpacing}px`, // 16px
    l: `${baseSpacing * 2}px`, // 32px
  },

  sizes: {
    xs: `${baseSize / 2}px`, // 16px
    s: `${baseSize}px`, // 32px
    m: `${baseSize * 2}px`, // 64px
    l: `${baseSize * 4}px`, // 128px
    xl: `${baseSize * 8}px`, // 256px
  },

  fonts: {
    headingLarge: `48px`, // 48px
    heading: `32px`, // 32px
    headingSmall: `24px`, // 24px
    extraLarge: `19px`, // 19px
    large: `17px`, // 17px
    baseSize: `15px`, // 15px
    small: `14px`, // 14px
    tiny: `12px`, // 12px
    extraTiny: `10px`, // 10px
  },

  colors: {
    // ToDO: all this colors should be migrated or deleted
    lightBlue: '#0BBBEF',
    yellow: '#FDB912',
    red: '#B31212',
    buttonBlue: '#00ACE0',
    black: '#232729',
    greyOne: '#B1B7BA',
    greyTwo: '#65696A',
    greyThree: '#ECF0F1', 
    white: '#FFFFFF',
    blackOne: '#222222',
    green: '#008641'
  },

  shadows: {
    top: '0px 2px 4px rgba(51, 92, 123, 0.15)',
    form: '0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
    modal: '0 2px 6px 3px rgba(239, 239, 245, 0.5)',
  },
}
