import { gql } from "graphql-request";

export const editarPeriodo = gql`
  mutation editarPeriodoMatricula(
    $id: ID!
    $nombre: String!
    $tipoPeriodo: Int!
    $fechaFin: Date!
    $fechaInicio: Date!
    $fechaConsulta: Date!
  ) {
    editarPeriodoMatricula(
      id: $id
      nombre: $nombre
      tipoPeriodo: $tipoPeriodo
      fechaFin: $fechaFin
      fechaInicio: $fechaInicio
      fechaConsulta: $fechaConsulta
    ) {
      periodoMatricula {
        id
        nombre
        tipoPeriodo {
          id
          nombre
        }
        fechaFin
        fechaInicio
        fechaConsulta
      }
      success
    }
  }
`;


export const eliminarGrupo = gql`
  mutation eliminarGrupo(
    $id: ID!

  ) {
    eliminarGrupo(
      id: $id
     
    ) {
      grupo{
      id
    }
    }
  }
`;