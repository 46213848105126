import { ReactNode, useContext } from "react";
import styled from "styled-components";
import Alerts from 'components/Alerts'
import AlertContext from 'context/AlertContext'

interface BaseLayoutProps {
  children: ReactNode;
}

export default function Content({ children }: BaseLayoutProps): JSX.Element {

  const alert = useContext(AlertContext);
  return (
    <Container>
      {/*@ts-ignore}*/}
      {alert.alert !== 'none' && <Alerts status={alert.alert} title={alert.alertText.text}/>}
      {children}
    </Container>
  );
}

const Container = styled.div`
  padding: 24px;
  position: relative;
  //height:100vh;

  @media (min-width: 768px) {
    padding: 48px;
    flex-direction: row;
  }

  @media (min-width: 1200px) {
    text-align: left;
    padding: 70px 120px;
    position: relative;
  }
`;

