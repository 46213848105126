import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  useGetGrupo,
  useGetPeriodo,
  useGetMatriculadosGrupo,
} from "hooks/useRequest";
import { Mutation } from "hooks/useMutation";
import { ExportToCsv } from "export-to-csv";
import { desmatricularDocente } from "./mutations";

export const useListaMatriculados = () => {
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [filteredDataDocente, setFilteredData] = useState();
  const history = useHistory();

  const { search } = useLocation();
  const { grupo, periodo } = queryString.parse(search);

  const { data, refetch } = useGetGrupo({
    //@ts-ignore
    id: parseInt(grupo),
  });

  const { data: periodoMatricula, refetch: refetchPeriodo } = useGetPeriodo({
    //@ts-ignore
    id: parseInt(periodo),
  });

  const {
    data: docentesMatriculados,
    isLoading,
    isFetching,
    refetch: refetchDocentesMatriculados,
  } = useGetMatriculadosGrupo({
    //@ts-ignore
    id: parseInt(grupo),
  });

  const refetchData = () => {
    refetchPeriodo();
    refetchDocentesMatriculados();
    refetch();
  };

  const mutation = Mutation(desmatricularDocente, refetchData);

  const handleOnEdit = (id: any) => {
    history.push({
      pathname: `/admin/editar-docente`,
      search: `?docente=${id}&grupo=${grupo}&periodo=${periodo}`,
    });
  };

  const handleDelete = (id: any) => {
    setDeleteModalVisible(true);
    setSelectedId(id);
  };

  const handleOkDelete = () => {
    /*@ts-ignore*/
    mutation.mutate({ docente: parseInt(selectedId), grupo: grupo });
    setDeleteModalVisible(false);
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
    setSelectedId(null);
    refetchData();
  };

  const handleGoBack = () => {
    history.push({
      pathname: "/admin/periodo-matricula",
      search: `?periodo=${periodo}`,
    });
  };

  const handleExport = () => {
    csvExporter.generateCsv(filteredDataDocente);
  };

  const handleClickMatricularDocente = () =>
    history.push(`/admin/lista-docentes?grupo=${data.id}&periodo=${periodo}`);

  useEffect(() => {
    const data = docentesMatriculados
      ? docentesMatriculados.map((data: any) => {
          data.docente.comprobante = `${process.env.REACT_APP_MEDIA_URL}${data.docente.comprobante}`;
          return data.docente;
        })
      : [];
    setFilteredData(data);
  }, [docentesMatriculados]);

  const exportPdfOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    filename: `lista-matriculados-${periodoMatricula?.nombre}-${data?.nombre}`,
    title: `Lista de Matriculados de ${periodoMatricula?.nombre} - ${data?.nombre} - ${data?.horario}: ${data?.horaEntrada} a ${data?.horaSalida} - ${data?.nombreProfesor}`,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(exportPdfOptions);

  return {
    docentesMatriculados,
    data,
    filteredDataDocente,
    isLoading,
    isFetching,
    isDeleteModalVisible,
    handleCancelDelete,
    handleOnEdit,
    handleDelete,
    handleGoBack,
    handleOkDelete,
    handleExport,
    handleClickMatricularDocente,
  };
};
