import styled from "styled-components";

import { BackButton, Title, HeaderContainer } from "components";
import { MatricularDocenteForm } from "components/Forms";
import { useMatricularCurso } from "./useMatricularCurso";

export function MatricularCurso() {
  const {
    tipoPeriodo,
    periodo,
    data,
    docente,
    initialValues,
    isSubmitting,
    isSuccess,
    isError,
    handleFinish,
    handleGoBack,
  } = useMatricularCurso();

  return (
    <PageContainer>
      <HeaderContainer>
        <BackButton text="Inicio" link={`/?periodo=${periodo}`} />
      </HeaderContainer>

      <Title title={data?.nombre} />
      {docente && (
        <MatricularDocenteForm
          submitButtonText={"Matricular"}
          onFinish={handleFinish}
          onCancel={handleGoBack}
          initialValues={initialValues}
          tipoPeriodo={tipoPeriodo}
          isSubmiting={isSubmitting}
          isError={isError}
          isSuccess={isSuccess}
        />
      )}
    </PageContainer>
  );
}

const PageContainer = styled.div`
  @media (min-width: 768px) {
    margin-bottom: 45px;
    padding-right: 86px;
    padding-left: 86px;
  }
`;
