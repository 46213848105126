import styled from "styled-components";
import { Table as antdTable } from "antd";

const BaseTable = styled(antdTable)`
  & {
    th.ant-table-cell {
      background-color: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.blackOne};
      padding-bottom: 12px;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      border-bottom: 0.5px solid ${(props) => props.theme.colors.greyOne};

      &:before {
        display: none;
      }
    }
    td.ant-table-cell {
      border-bottom: none;
      padding: 10px 16px;
      * {
        font-weight: normal;
        font-size: 18px;
        line-height: 31px;
        color: ${(props) => props.theme.colors.greyTwo};
      }
    }
  }
`;

export function Table({ ...props }): JSX.Element {
  return <BaseTable {...props} />;
}
