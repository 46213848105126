import { gql } from "graphql-request";

export const crearGrupo = gql`
  mutation crearGrupo(
    $nombre: String!
    $horario: String!
    $tipoSede: Boolean!
    $cupo: Int!
    $periodoMatricula: Int!
    $profesor: String
    $horaEntrada: String
    $horaSalida: String
  ) {
    crearGrupo(
      nombre: $nombre
      horario: $horario
      tipoSede: $tipoSede
      cupo: $cupo
      periodoMatricula: $periodoMatricula
      nombreProfesor: $profesor
      horaEntrada: $horaEntrada
      horaSalida: $horaSalida
    ) {
      grupo {
        id
        nombre
      },
      success
    }
  }
`;

 