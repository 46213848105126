import styled from "styled-components";

import {
  EditOutlined,
  DeleteOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { Button, Table } from "library";
import {
  BackButton,
  CustomModal,
  Spinner,
  HeaderContainer,
  Group,
  Heading2,
} from "components";

import { useListaMatriculados } from "./useListaMatriculados";

export function ListaMatriculados() {
  const {
    data,
    docentesMatriculados,
    filteredDataDocente,
    isLoading,
    isFetching,
    isDeleteModalVisible,
    handleCancelDelete,
    handleOnEdit,
    handleDelete,
    handleGoBack,
    handleOkDelete,
    handleExport,
    handleClickMatricularDocente,
  } = useListaMatriculados();
  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Identificación",
      dataIndex: "identificacion",
      key: "identificacion",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Unidad Académica",
      dataIndex: "unidad",
      key: "unidad",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Carrera",
      dataIndex: "carrera",
      key: "carrera",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Nombramiento",
      dataIndex: "condicion",
      key: "condicion",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Correo Institucional",
      dataIndex: "correoInstitucional",
      key: "correoInstitucional",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Correo Secundario",
      dataIndex: "correoSecundario",
      key: "correoSecundario",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Teléfono",
      dataIndex: "telefono",
      key: "telefono",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Comprobante",
      dataIndex: "comprobante",
      key: "comprobante",
      render: (fileUrl: string) => (
        <StyleDownloadLink>
          <FilePdfOutlined />
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            Descargar
          </a>
        </StyleDownloadLink>
      ),
    },
    {
      title: "Opciones",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      render: (record: any) => (
        <ActionsList>
          <StyledIconButton
            onClick={() => handleOnEdit(record)}
            variant="link"
            icon={<EditOutlined />}
          />
          <StyledIconButton
            onClick={() => handleDelete(record)}
            variant="link"
            icon={<DeleteOutlined />}
          />
        </ActionsList>
      ),
    },
  ];

  return (
    <>
      <HeaderContainer>
        <BackButton
          text="Periodo de Matrícula"
          onClick={() => handleGoBack()}
          link="/admin"
        />
      </HeaderContainer>

      {docentesMatriculados && data && (
        <>
          <Heading2 style={{ marginBottom: "16px" }}>{data.nombre}</Heading2>
          <Group
            key={data.id}
            id={data.id}
            nombre={data.nombre}
            tipoSede={data.tipoSede}
            horario={data.horario}
            profesor={data.nombreProfesor}
            horaEntrada={data.horaEntrada}
            horaSalida={data.horaSalida}
            onDelete={handleCancelDelete}
            cupo={data.cupo}
            displayOptions={false}
            displayName={false}
            cupoDisponible={data.cupoDisponible}
          />
          <DesktopTable>
            <StyledTable
              columns={columns}
              dataSource={filteredDataDocente}
              loading={{
                spinning: isLoading || isFetching,
                indicator: <Spinner isLoading={isLoading || isFetching} />,
              }}
              scroll={{ x: 2000 }}
            />
          </DesktopTable>
          <ButtonsContainer>
            <StyledButton variant="secondary" onClick={handleExport}>
              Exportar lista
            </StyledButton>
            <StyledButton onClick={handleClickMatricularDocente}>
              Matricular docente
            </StyledButton>
          </ButtonsContainer>
          <CustomModal
            okText="Eliminar"
            cancelText="Cancelar"
            title="Eliminar Docente"
            text={"¿Está seguro que desea eliminar el docente seleccionado?"}
            visible={isDeleteModalVisible}
            onOk={handleOkDelete}
            onCancel={handleCancelDelete}
            variant="danger"
          />{" "}
        </>
      )}
    </>
  );
}

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 1200px) {
    top: 175px;
    right: 120px;
    position: absolute;
  }
`;

const StyledButton = styled(Button)`
  width: 312px;
  margin: 0 auto;
  display: block;
  &:first-of-type {
    margin-right: 20px;
  }
  @media (min-width: 768px) {
    width: 320px;
  }
  @media (min-width: 1200px) {
    width: 282px;
  }
`;

const DesktopTable = styled.div`
  //display: none;
  @media (min-width: 1200px) {
    display: block;
  }
`;

const ActionsList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIconButton = styled(Button)`
  width: auto;
  min-width: auto;
  box-shadow: none;
  background: transparent;
  padding: 0 12px;
`;

const StyledTable = styled(Table)`
  td.ant-table-cell * {
    font-size: 18px;
    line-height: 25.2px;
    color: ${(props) => props.theme.colors.blackOne};
  }
`;

const StyleDownloadLink = styled.p`
  a {
    margin-left: 5px;
    color: ${(props) => props.theme.colors.lightBlue} !important;
  }
`;
