import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useGetDocentesPeriodo, useGetGrupo } from "hooks/useRequest";
import { useState } from "react";

export const useListaDocentes = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { grupo, periodo } = queryString.parse(search);
  const [filteredDataDocentes, setFilteredData] = useState([]);

  const {
    data: dataDocentes,
    isFetching,
    isLoading,
  } = useGetDocentesPeriodo({
    //@ts-ignore
    id: parseInt(periodo),
  });

  const { data: dataGrupo } = useGetGrupo({
    //@ts-ignore
    id: parseInt(grupo),
  });

  const handleSearchChange = (value: any) => {
    const filteredDataDocentes = dataDocentes.docenteSet.filter(
      (docente: any) => {
        return docente.identificacion === value.target.value;
      }
    );
    setFilteredData(filteredDataDocentes);
  };

  const handleGoToAdd = () => {
    history.push({
      pathname: "/admin/matricular-docente/",
      search: `?grupo=${grupo}&periodo=${periodo}`,
      state: { tipoPeriodo: dataDocentes.tipoPeriodo.id },
    });
  };

  const handleGoToMatricular = (record:any) => {
    history.push({
        pathname: "/admin/matricular-docente/",
        search: `?grupo=${grupo}&periodo=${periodo}`,
        state: { docente: record, tipoPeriodo: dataDocentes.tipoPeriodo.id },
      })
  }

  return {
    grupo, 
    periodo,
    dataDocentes,
    dataGrupo,
    filteredDataDocentes,
    isFetching,
    isLoading,
    handleSearchChange,
    handleGoToAdd,
    handleGoToMatricular
  };
};
