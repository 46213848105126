import { useMutation } from "react-query";
import { GraphQLClient } from "graphql-request";
import AlertContext from 'context/AlertContext';
import { useContext } from "react";

const API_URL = process.env.REACT_APP_API_URL as string;

const graphQLClient = new GraphQLClient(API_URL, {
  //@ts-ignore
  headers: {
    authorization: `JWT ${localStorage.getItem('AUTH_TOKEN')}`,
  },
});

export const Mutation = (gql:any, onSuccess?: any) => {
  const alert = useContext(AlertContext);
  const mutation = useMutation(async (value): Promise<any> => {
    try {
      const data  = await graphQLClient.request(
        gql,
        value
      );
      const currentObj = Object.keys(data)[0];
      /*@ts-ignore*/
      alert.success(data[currentObj].success)
      return {
        data,
      };
    } catch (error) {
      /*@ts-ignore*/
      alert.error(error.response.errors[0]['message'])
      return{
        data: null
      }
    }
  },{
    onSuccess: async() => onSuccess && await onSuccess(),
    onError: (err, variables, context)=> console.log(err, variables)
  });
  return mutation;
};
