import { useHistory } from "react-router-dom";

import { Body2, Body3 } from "components/Typography";
import styled from "styled-components";
import { Button } from "library";
import {
  EditOutlined,
  DeleteOutlined,
  ClockCircleOutlined,
  UserAddOutlined,
} from "@ant-design/icons";

interface GroupProps {
  id: number;
  horario: string;
  cupo?: number;
  nombre: String;
  tipoSede: boolean;
  onDelete?: any;
  profesor?: string;
  horaEntrada?: string;
  horaSalida?: string;
  displayOptions?: Boolean;
  periodo?: string | string[] | null;
  displayName?: boolean;
  cupoDisponible?: number;
}

export const Group = ({
  id,
  horario,
  cupo,
  nombre,
  tipoSede,
  onDelete,
  profesor,
  horaEntrada,
  horaSalida,
  displayOptions = true,
  periodo,
  displayName = true,
  cupoDisponible,
}: GroupProps): JSX.Element => {
  const history = useHistory();

  const onGroupEdit = () => {
    history.push({
      pathname: "/admin/editar-grupo",
      search: `?grupo=${id}`,
    });
  };

  const onGroupDetails = () => {
    history.push({
      pathname: "/admin/lista-matriculados",
      search: `?grupo=${id}&periodo=${periodo}`,
    });
  };

  //@ts-ignore
  const cuposDisponibles = cupoDisponible < 0 ? 0 : cupoDisponible;
  //@ts-ignore

  return (
    <GroupContainer>
      <GroupDataContainer>
        {displayName && (
          <Body2>
            {nombre} {tipoSede ? "- Tipo Sede" : null}{" "}
          </Body2>
        )}
        <InfoContainer>
          <Body3>{profesor}</Body3>
        </InfoContainer>
        <InfoContainer style={{ flexDirection: "column" }}>
          <Body3>
            <ClockCircleOutlined style={{ display: "none" }} /> {horario}:{" "}
            {horaEntrada} - {horaSalida}
          </Body3>
          <Body3 style={{ marginTop: "10px" }}>
            {cupo} cupos ( <strong>{cuposDisponibles} disponibles</strong> )
          </Body3>
        </InfoContainer>
      </GroupDataContainer>
      {displayOptions && (
        <ActionsList>
          <StyledIconButton
            onClick={onGroupEdit}
            variant="link"
            icon={<EditOutlined />}
          />
          <StyledIconButton
            onClick={() => onGroupDetails()}
            variant="link"
            icon={<UserAddOutlined />}
          />

          <StyledIconButton
            onClick={() => onDelete({ id: id, nombre: nombre })}
            variant="link"
            icon={<DeleteOutlined />}
          />
        </ActionsList>
      )}
    </GroupContainer>
  );
}

const GroupContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const GroupDataContainer = styled.div`
  display: block;
  margin-bottom: 45px;
`;

const ActionsList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIconButton = styled(Button)`
  width: auto;
  min-width: auto;
  box-shadow: none;
  background: transparent;
  padding: 0 4px;
  align-items: flex-start;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 0;
`;
