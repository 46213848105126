import styled from "styled-components";
import { Select as antdSelect } from "antd";
import { DownOutlined } from "@ant-design/icons";

const BaseSelect = styled(antdSelect)`
  &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: transparent;
    color: ${(props) => props.theme.colors.greyTwo} !important;
  }
  & {
    .ant-select-selector {
      border: none !important;
      border-bottom: 1px solid ${(props) => props.theme.colors.greyOne} !important;
      border-radius: 0 !important;
      padding: 0 !important;
      .ant-select-selection-placeholder {
        color: ${(props) => props.theme.colors.black} !important;
        font-size: 16px;
        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 28px;
        }
      }
      .ant-select-selection-item {
        font-size: 16px;
        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
    .ant-select-arrow {
      right: 0;
    }
  }
`;

export function Select({ ...props }): JSX.Element {
  return (
    <BaseSelect
      suffixIcon={<DownOutlined style={{ color: "#65696A" }} />}
      {...props}
    />
  );
}
