import styled from "styled-components";
import {
  Form,
  Button,
  Input,
  Row,
  Col,
  Select,
  InputNumber,
  Upload,
} from "library";
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import { message } from "antd";
import { Body4 } from "components/Typography";

interface CrearPeriodoFormProps {
  onFinish: any;
  desktopAlign?: boolean;
  onCancel: any;
  initialValues?: any;
  submitButtonText?: string;
  tipoPeriodo: number;
  isSubmiting?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
}

const TiposNombramiento = [
  { label: "Propiedad", value: "PROPIEDAD" },
  { label: "Interino de más de dos años", value: "INTERINO_MAS" },
  { label: "Interino de menos de dos años", value: "INTERINO_MENOS" },
];

const TiposIdentificacion = [
  { label: "Nacional", value: 1 },
  { label: "Pasaporte", value: 2 },
];

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export function MatricularDocenteForm({
  onFinish,
  desktopAlign = false,
  onCancel,
  initialValues = null,
  submitButtonText = "Crear Grupo",
  tipoPeriodo,
  isSubmiting=false,
  isError,
  isSuccess
}: CrearPeriodoFormProps) {
  const disabledField = initialValues ? true : false;

  return (
    <FormContainer>
      <Form
        {...formItemLayout}
        onFinish={(values) => onFinish({ ...values })}
        name="basic"
        initialValues={initialValues}
      >
        <Row gutter={130}>
          <Col xs={24} lg={desktopAlign ? 24 : 12}>
            <Form.Item
              label="Nombre del docente"
              name="name"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Input disabled={disabledField} />
            </Form.Item>

            <Form.Item label="Tipo de Nombramiento" name="nombramiento">
              <Select
                options={TiposNombramiento}
                placeholder="Seleccione el tipo de nombramiento"
                disabled={disabledField}
              />
            </Form.Item>
            <Row gutter={32}>
              <Col xs={12}>
                <Form.Item label="Tipo de Identificacion" name="tipoId">
                  <Select
                    options={TiposIdentificacion}
                    placeholder="Seleccionar"
                    disabled={disabledField}
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item label="Número de Identificación" name="id">
                  <InputNumber type="id" min={1} disabled={disabledField} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col xs={24}>
                <Form.Item label="Unidad Académica" name="unidad">
                  <Input disabled={disabledField} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label="Carrera"
                  name="carrera"
                  rules={[{ required: true, message: "Campo requerido" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={desktopAlign ? 24 : 12}>
            <Form.Item
              label="Correo electrónico institucional "
              name="mail"
              rules={[
                { required: true, message: "Campo requerido" },
                {
                  type: "email",
                  message: "Ingrese un correo electrónico válido",
                },
              ]}
            >
              <Input disabled={disabledField} />
            </Form.Item>
            <Form.Item
              label="Correo electrónico secundario "
              name="secondaryMail"
              rules={[
                { required: true, message: "Campo requerido" },
                {
                  type: "email",
                  message: "Ingrese un correo electrónico válido",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Row gutter={32}>
              <Col xs={24}>
                <Form.Item
                  label="Número telefónico "
                  name="phone"
                  rules={[{ required: true, message: "Campo requerido" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {tipoPeriodo == 1 && (
              <>
                <Form.Item
                  label="Adjuntar comprobante de función docente en PDF"
                  name="comprobante"
                  rules={[{ required: true, message: "Campo requerido" }]}
                >
                  <Upload
                    accept=".pdf"
                    beforeUpload={(file) => {
                      const isPDF = file.type === "application/pdf";
                      if (!isPDF) {
                        message.error(
                          "Debe adjuntar un archivo en formato PDF"
                        );
                        return Upload.LIST_IGNORE;
                      }

                      const isLessThan2MB = file.size / 1024 / 1024 < 2;
                      if (!isLessThan2MB) {
                        message.error(
                          "El tamaño del archivo debe ser menor a 2 MB"
                        );
                        return Upload.LIST_IGNORE;
                      }

                      return false;
                    }}
                    maxCount={1}
                  >
                    <Button variant="link" icon={<UploadOutlined />}>
                      Adjuntar archivo
                    </Button>
                  </Upload>
                </Form.Item>
                <Body4>
                  Documento oficial en el que se indique el nombre y sigla del
                  curso (o cursos) que la persona va a desarrollar en este ciclo
                  lectivo.
                </Body4>
              </>
            )}
            <ButtonContainer>
              <Button onClick={() => onCancel()} variant="secondary">
                Cancelar
              </Button>
              <Button disabled={isSubmiting || isSuccess || isError} htmlType="submit">{submitButtonText}</Button>
            </ButtonContainer>
          </Col>
        </Row>
      </Form>
    </FormContainer>
  );
}

const FormContainer = styled.div`
  margin-top: 24px;
  .ant-form-item {
    margin-bottom: 32px;
    @media (min-width: 768px) {
      margin-bottom: 45px;
    }
  }
  .ant-form-item-label {
    padding-bottom: 0;
  }
  label {
    font-size: 12px;
    line-height: 19px;
    font-weight: bold;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 22px;
    }
    @media (min-width: 1200px) {
      line-height: 18px;
    }
  }
  @media (min-width: 1200px) {
    margin-bottom: 48px;
  }
  button {
    text-align: right;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  button {
    flex: 50%;
    &:first-of-type {
      margin-right: 24px;
    }
  }
  @media (min-width: 1200px) {
    margin-top: 80px;
  }
`;
