import styled from "styled-components";
import {Button} from 'library'
import {LeftOutlined} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

interface BackButtonProps {
  text: string;
  link: string;
  onClick?: ()=> void;
}

export const BackButton = ({text, link, onClick=()=>{return false}}: BackButtonProps): JSX.Element => {
  const history = useHistory();

  const onClickHandler = ()=>{
    history.push(link)
    //@ts-ignore
    onClick()
  }

  return (
    <Container>
        <StyledButton onClick={onClickHandler} variant="link" icon={<LeftOutlined />}>{text}</StyledButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: start;
`;


const StyledButton = styled(Button)`
   font-size: 16px;
   color: ${(props) => props.theme.colors.greyTwo};
   span{
       margin-left: 10px !important;
   }
   .anticon{
       margin: 0 !important;
       line-height: 0 !important;

   }

`