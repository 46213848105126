import { gql } from "graphql-request";

export const matricularDocente = gql`
  mutation matricularDocente(
    $idDocente: Int
    $correoSecundario: String
    $carrera: String
    $telefono: String
    $comprobante: Upload
    $grupo: Int
  ) {
    matricularDocente(
      idDocente: $idDocente
      correoSecundario: $correoSecundario
      carrera: $carrera
      telefono: $telefono
      comprobante: $comprobante
      grupo: $grupo
    ) {
      docente {
        id
        identificacion
        nombre
      }
      success
    }
  }
`;
