import {Heading3} from  'components/Typography'

interface TitletProps {
  title: string;
}

export const Title = ({ title }: TitletProps): JSX.Element => {
  return (
    <Heading3 color="#232729" style={{fontWeight: 400}}>{title}</Heading3>
  );
}
