import dayjs from 'dayjs'
import 'dayjs/locale/es' // import locale

dayjs.locale('es')

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export const humanLegibleDate = (date: string) => {
    return dayjs(date).format('D [de] MMMM');
}

export const getDayDiff = (date: string) => {

    const now = dayjs().format('YYYY-MM-DD')
    return dayjs(date).diff(now, 'day', true)
}

export const isSystemAvailable = () => {
    const currentHour = dayjs().hour();
    return currentHour >= 9 && currentHour < 17;
}