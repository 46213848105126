import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetPeriodos } from "hooks/useRequest";
import { Mutation } from "hooks/useMutation";
import { eliminarPeriodoMatricula } from "./mutations";

const DEFAULT_TAB = '1'

export const useAdmin = () => {
  const { data, refetch, isLoading, isFetching } = useGetPeriodos();

  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState<string|null>(null);
  const [selectedTab, setSelectedTab] = useState(DEFAULT_TAB);
  const mutation = Mutation(eliminarPeriodoMatricula, refetch);

  const history = useHistory();

  const handleEdit = (id: string) => {
    history.push({
      pathname: `/admin/periodo-matricula/`,
      search: `?periodo=${id}`,
    });
  };

  const handleDelete = (id: string) => {
    setModalVisible(true);
    setSelectedId(id);
  };

  const handleOkDelete = () => {
    /*@ts-ignore*/
    mutation.mutate({ id: selectedId });
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setSelectedId(null);
  };

  const handleSelectedTab = (tabIndex: string) => {
    setSelectedTab(tabIndex)
  }

  const handleAddNew = () =>{
    history.push({
        pathname: "/admin/crear-periodo",
        state: { type: selectedTab },
      })
  }

  return {
    firstTypeData: data?.filter((d:any)=>d.tipoPeriodo.id === '1'),
    secondTypeData: data?.filter((d:any)=>d.tipoPeriodo.id === '2'),
    isLoading, 
    isFetching,
    isModalVisible,
    selectedId,
    selectedTab,
    handleEdit,
    handleCancel,
    handleDelete,
    handleOkDelete,
    handleSelectedTab,
    handleAddNew
  };
};
