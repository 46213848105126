import styled from "styled-components";

interface TypographyProps {
  variant?: string;
  color?: string;
  children?: any;
}

const Heading1 = styled.h1<TypographyProps>`
  font-weight: 300;
  font-size: 28px;
  line-height: 39px;
  color: ${(props) => props.color};
  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 56px;
  }
  @media (min-width: 1200px) {
    font-size: 48px;
    line-height: 67px;
  }
`;


const Heading2 = styled.h2<TypographyProps>`
  font-weight: 400;
  font-size: 28px;
  line-height: 39px;
  color: ${(props) => props.color};
  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 39px;
  }
  @media (min-width: 1200px) {
    font-size: 36px;
    line-height: 50.4px;
  }
`;

const Heading3 = styled.h3<TypographyProps>`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: ${(props) => props.color};
  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 39px;
  }
  @media (min-width: 1200px) {
    font-size: 36px;
    line-height: 39px;
    font-weight: 300;
  }
`;

const Body1 = styled.p<TypographyProps>`
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 34px;
  }
`;

const Body2 = styled.p<TypographyProps>`
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 31px;
  }
`;

const Body3 = styled.p<TypographyProps>`
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  color: ${(props) => props.color};
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

const Body4 = styled.p<TypographyProps>`
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  color: ${(props) => props.color};
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
    line-height: 28px;
  }
`;

const Overline = styled.p<TypographyProps>`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.color};
  @media (min-width: 1200px) {
    line-height: 26px;
  }
`;

export { Heading1, Heading2, Heading3, Body1, Body2, Body3, Body4, Overline };
