import styled from "styled-components";
import { EditOutlined, DeleteOutlined, FileOutlined } from "@ant-design/icons";
import { Button } from "library";

interface ActionListProps {
  onEdit?: any;
  onDelete?: any;
  onDetails?: any;
}

export const ActionList = ({
  onEdit,
  onDelete,
  onDetails,
}: ActionListProps) => {
  return (
    <ActionsList>
      <StyledIconButton
        onClick={onEdit}
        variant="link"
        icon={<EditOutlined />}
      />
      <StyledIconButton
        onClick={onDelete}
        variant="link"
        icon={<DeleteOutlined />}
      />
      <StyledIconButton
        onClick={onDetails}
        variant="link"
        icon={<FileOutlined />}
      />
    </ActionsList>
  );
}

const ActionsList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIconButton = styled(Button)`
  width: auto;
  min-width: auto;
  box-shadow: none;
  background: transparent;
  padding: 0 8px;
`;
