import { Body3, Heading2 } from "components/Typography";
import styled from "styled-components";
import {humanLegibleDate} from 'utilites/dateFormat'

interface UpcomingProps {
  title: string;
  fechaInicio: string;
  fechaFin: string;
}

export default function Upcoming({
  title,
  fechaInicio,
  fechaFin
}: UpcomingProps) {

const humanRedableStartDate = humanLegibleDate(fechaInicio);
const humanRedableEndDate = humanLegibleDate(fechaFin);

return (
    <div>
        <Body3>Próximo periodo de matrícula:</Body3>
        <Heading2>{title}</Heading2>
        <StyledDate>
            <Body3>La matrícula será:</Body3>
            <Body3>
              <strong>{humanRedableStartDate} al {humanRedableEndDate} a partir de las 9:00 a.m</strong>
            </Body3>
          </StyledDate>
    </div>
  );
}

const StyledDate = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
`;
