/*import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { isAdminAuthenticated } from 'utilites/isAuthenticated';

const PrivateAdminRoute = ({ component: Component, path }: RouteProps) => {

  if (isAdminAuthenticated) {
    return <Route component={Component} path={path} />;
  }else{
    return <Redirect to="/admin/login" />;
  }

};

export default PrivateAdminRoute;
*/

import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { isAdminAuthenticated } from "utilites/isAuthenticated";

const PrivateAdminRoute = ({ component: Component, path }: RouteProps) => {
  if (!isAdminAuthenticated()) {
    return <Redirect to="/admin/login" />;
  }
  return <Route component={Component} path={path} />;
};

export default PrivateAdminRoute;
