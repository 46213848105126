import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { BackButton } from "components";
import { MatricularDocenteForm } from "components/Forms";
import { crearDocente, matricularDocente } from "./mutation";
import { Mutation } from "hooks/useMutation";
import { useEffect } from "react";
import { useGetGrupo } from "hooks/useRequest";
import { Heading2 } from "components/Typography";
import { HeaderContainer, Group } from "components";

export function MatricularDocente(props?: any) {
  const history = useHistory();
  const { search } = useLocation();

  const mutationCrearDocente = Mutation(crearDocente);
  const mutationMatricularDocente = Mutation(matricularDocente);

  const { grupo, periodo } = queryString.parse(search);

  const { data: dataGrupo } = useGetGrupo({
    //@ts-ignore
    id: parseInt(grupo),
  });

  //@ts-ignore
  const tipoPeriodo = history.location.state.tipoPeriodo;
  //@ts-ignore
  const docente = history.location.state.docente || null;

  const initialValues = docente
    ? {
        name: docente.nombre,
        nombramiento: docente.condicion,
        mail: docente.correoInstitucional,
        secondaryMail: docente.correoSecundario,
        id: docente.identificacion,
        tipoId: docente.tipo,
        carrera: docente.carrera,
        unidad: docente.unidad,
        comprobante: null,
        phone: docente.telefono,
        periodoMatricula: periodo,
        sede: docente.sede,
      }
    : null;

  const onFinish = (values: any) => {
    let formattedValues = {};

    if (!docente) {
      formattedValues = {
        //@ts-ignore
        nombre: values.name,
        condicion: values.nombramiento,
        correoInstitucional: values.mail,
        correoSecundario: values.secondaryMail,
        grupo: grupo,
        identificacion: values.id,
        tipo: values.tipoId,
        carrera: values.carrera,
        unidad: values.unidad,
        comprobante: values.comprobante.file,
        telefono: values.phone,
        periodoMatricula: periodo,
        sede: 1,
      };
      /*@ts-ignore*/
      mutationCrearDocente.mutate(formattedValues);
    } else {
      const formattedValues = {
        //@ts-ignore
        idDocente: docente.id,
        correoSecundario: values.secondaryMail,
        carrera: values.carrera,
        telefono: values.phone,
        comprobante: values.comprobante.file,
        grupo: grupo,
        sede: docente.sede,
      };
      /*@ts-ignore*/
      mutationMatricularDocente.mutate(formattedValues);
    }
  };

  const goBack = () => {
    history.push({
      pathname: "/admin/lista-matriculados",
      search: `?grupo=${grupo}&periodo=${periodo}`,
    });
  };

  useEffect(() => {
    if (mutationCrearDocente.isSuccess || mutationMatricularDocente.isSuccess) {
      goBack();
    }
  }, [mutationCrearDocente, history, mutationMatricularDocente]);

  return (
    <PageContainer>
      <HeaderContainer>
        <BackButton
          text="Lista de Docentes"
          link={`/admin/lista-docentes?grupo=${grupo}&periodo=${periodo}`}
        />
      </HeaderContainer>

      <Heading2 style={{ marginBottom: "16px" }}>{dataGrupo.nombre}</Heading2>
      <Group
        key={dataGrupo.id}
        id={dataGrupo.id}
        nombre={dataGrupo.nombre}
        tipoSede={dataGrupo.tipoSede}
        horario={dataGrupo.horario}
        profesor={dataGrupo.nombreProfesor}
        horaEntrada={dataGrupo.horaEntrada}
        horaSalida={dataGrupo.horaSalida}
        cupo={dataGrupo.cupo}
        displayOptions={false}
        displayName={false}
        cupoDisponible={dataGrupo.cupoDisponible}
      />

      <MatricularDocenteForm
        submitButtonText={"Matricular"}
        onFinish={onFinish}
        onCancel={() => {
          goBack();
        }}
        initialValues={initialValues}
        tipoPeriodo={tipoPeriodo}
      />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  @media (min-width: 768px) {
    margin-bottom: 45px;
    padding-right: 86px;
    padding-left: 86px;
  }
`;
