import { gql } from "graphql-request";

export const eliminarPeriodoMatricula = gql`
  mutation eliminarPeriodoMatricula(
    $id: Int
  ) {
    eliminarPeriodoMatricula(
      id: $id
    ) {
      periodoMatricula {
        id
        fechaInicio
        fechaFin
        nombre
      }
      success
    }
  }
`;