import styled from "styled-components";
import { Body3 } from "components/Typography";
import NotFound from "./components/NotFound";
import Upcoming from "./components/Upcoming";
import Available from "./components/Available";

import LoginForm from "./components/LoginForm";
import { useGetPeriodoDisponible } from "hooks/useRequest";
import {getDayDiff} from 'utilites/dateFormat'

import dayjs from "dayjs";
import { useEffect, useState } from "react";

export default function Login() {
  const [dateDiff, setDateDiff] = useState(0);

  const { data } = useGetPeriodoDisponible();


  useEffect(() => {
    if (data) {
      const { fechaInicio } = data;
      setDateDiff(getDayDiff(fechaInicio));
    }else{
      setDateDiff(16)
    }
  }, [data]);

  const currentHour = dayjs().hour();
  const availableHours = currentHour >= 8 && currentHour <= 17;

  return (
    <LoginContainer>
      <Body3 style={{ marginBottom: "32px" }}>
        <strong>Sistema de Matrícula DEDUN</strong>
      </Body3>
      {dateDiff > 15 && <NotFound />}
      <FormContainer>
        {dateDiff < 15 && dateDiff > 0 && (
          <Upcoming
            title={data?.nombre}
            fechaInicio={data?.fechaInicio}
            fechaFin={data?.fechaFin}
          />
        )}
         {dateDiff <= 0 && (
          <Available
            title={data?.nombre}
            fechaFin={data?.fechaFin}
          />
        )}

        {dateDiff <= 0 && (
          <LoginForm availableHours={availableHours} periodo={data?.id} dateDiff={dateDiff} tipoPeriodo={data?.tipoPeriodo} fechaInicio={data?.fechaInicio}/>
        )}
      </FormContainer>

    </LoginContainer>
  );
}

const LoginContainer = styled.div`
  padding: 26px 0;
  @media (min-width: 768px) {
    padding: 26px 86px;
  }
  @media (min-width: 1200px) {
    padding: 72px 100px;
    max-width: 1200px;
    margin: 0 auto;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1200px) {
    max-width: 992px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 126px;
    grid-row-gap: 0px;
    margin: 83px 0 112px 0;
  }
`;
