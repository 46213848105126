import styled from "styled-components";
import { Input as antdInput } from "antd";

const BaseInput = styled(antdInput)`
  &.ant-input[disabled] {
    background-color: transparent;
    color: ${(props) => props.theme.colors.greyTwo} !important;
  }
  .ant-input-number-disabled {
    background-color: transparent;
    color: ${(props) => props.theme.colors.greyTwo} !important;
  }
  & {
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.greyOne};
    width: 100%;
    height: 30px;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    font-family: "Oxygen", sans-serif;
    padding: 0;
    outline: 0;
    border-radius: 0;
    min-width: 1px;
    color: ${(props) => props.theme.colors.black};;
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 28px;
    }
    &:hover,
    &:focus {
      border-bottom: 1px solid ${(props) => props.theme.colors.buttonBlue};
      outline: none;
    }
  }
`;

export function Input({ ...props }): JSX.Element {
  return <BaseInput {...props} />;
}
