import { Button } from "library";
import styled from "styled-components";
import { LogoutOutlined } from "@ant-design/icons";
import { Body3 } from "components/Typography";

interface ProfileProps {
  name?: string | null;
  path: string;
}

const Profile = ({ name = null, path }: ProfileProps) => {

  const initials = name
    ? name
        .split(" ")
        .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
        .join("")
    : "";

  const logOut = () => {
    const item = path === "admin" ? "AUTH_TOKEN" : "user";
    if (item === "AUTH_TOKEN") {
      localStorage.removeItem("AUTH_TOKEN");
      window.location.href = '/admin/login';

    }
    if (item === "user") {
      localStorage.removeItem("periodo");
      localStorage.removeItem("docente");
      localStorage.removeItem("user");
      localStorage.removeItem("sede");
      window.location.href = '/matricula/login';
    }
  };

  return (
    <ProfileContainer>
      <Body3 style={{ marginRight: "25px" }}>
        <strong>{name}</strong>
      </Body3>
      <LogOutContainer>
        <Avatar>
          <span>{initials}</span>
        </Avatar>
        <ButtonContainer>
          <Button
            onClick={() => logOut()}
            icon={<LogoutOutlined />}
            variant="link"
          >
            Salir
          </Button>
        </ButtonContainer>
      </LogOutContainer>
    </ProfileContainer>
  );
};

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (min-width: 1200px) {
    justify-content: center;
    width: auto ;
  }
`;

const Avatar = styled.div`
  background: #f37021;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 12px;
    color: ${(props) => props.theme.colors.white};
    font-weight: 700;
  }
`;

const ButtonContainer = styled.div`
  button {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    span {
      margin: 0 5px;
    }
  }
`;

const LogOutContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default Profile;
