import styled from "styled-components";
import { DatePicker as antdPicker } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

const BaseDatePicker = styled(antdPicker)`
  & {
    border: none !important;
    border-bottom: 1px solid ${(props) => props.theme.colors.greyOne} !important;
    border-radius: 0 !important;
    width: 100%;
    padding: 0 !important;
    input {
      color: ${(props) => props.theme.colors.black};
      font-size: 16px;
      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 28px;
      }
      &::placeholder,
      &::selection {
        color: ${(props) => props.theme.colors.black} !important;
      }
    }
  }
`;

export function DatePicker({ ...props }): JSX.Element {
  const dateFormat = "DD/MM/YYYY";

  return (
    <BaseDatePicker
      format={dateFormat}
      suffixIcon={<CalendarOutlined style={{ color: "#65696A" }} />}
      {...props}
    />
  );
}
