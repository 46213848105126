import { gql } from "graphql-request";

export const eliminarDocente = gql`
  mutation eliminarDocente(
    $id: ID
  ) {
    eliminarDocente(
      id: $id
    ) {
      docente {
        identificacion
        nombre
      }
    }
  }
`;

export const desmatricularDocente = gql`
  mutation desmatricularDocente($docente: Int, $grupo: Int) {
    desmatricularDocente(docente: $docente, grupo: $grupo) {
      matriculado {
        docente {
          id
        }
      }
      success
    }
  }
`;
