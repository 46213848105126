import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import styled from "styled-components";
import { BackButton, Title } from "components";
import { CrearGrupoForm } from "components/Forms";
import { Mutation } from "hooks/useMutation";
import { crearGrupo } from "./mutations";
import moment from "moment";

export default function CrearGrupo() {
  const mutation = Mutation(crearGrupo);
  const history = useHistory();

  const { search } = useLocation();
  const { periodo } = queryString.parse(search);

  const onFinish = (values: any) => {
    const formattedValues = {
      nombre: values.name,
      horario: values.day,
      tipoSede: values.type,
      cupo: values.qty,
      periodoMatricula: periodo,
      horaEntrada: moment(values.startTime).format("h:mm a"),
      horaSalida: moment(values.endTime).format("h:mm a"),
      profesor: values.profesor,
    };
    /*@ts-ignore*/
    mutation.mutate(formattedValues);
  };

  const goBack = () => {
    history.push({
      pathname: "/admin/periodo-matricula",
      search: `?periodo=${periodo}`,
    });
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      goBack();
    }
  }, [mutation, history, periodo]);

  return (
    <PageContainer>
      <BackButton text="Inicio" onClick={() => goBack()} link="/admin" />
      <Title title="Nuevo Grupo" />
      <CrearGrupoForm onCancel={goBack} onFinish={onFinish} />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  @media (max-width: 1200px) {
    margin-bottom: 45px;
    padding-right: 86px;
    padding-left: 86px;
  }
`;
