import { Heading1 } from "components/Typography";
import { Button } from "library";
export default function NotFound() {

  return (
    <div style={{marginBottom: '60px'}}>
    <Heading1 style={{marginBottom: '60px'}}>No hay ningún periodo de matrícula activo en este momento.</Heading1>
    <Button href={"http://docenciauniversitaria.ucr.ac.cr"} variant={"secondary"}>Visitar página del DEDUN</Button>
    </div>
  );
}