import styled from "styled-components";
import { Button, Table, Input } from "library";
import {
  BackButton,
  Spinner,
  HeaderContainer,
  Group,
  Heading2,
} from "components";

import { useListaDocentes } from "./useListaDocentes";

export function ListaDocentes() {
  const {
    grupo,
    periodo,
    dataDocentes,
    dataGrupo,
    filteredDataDocentes,
    isLoading,
    isFetching,
    handleSearchChange,
    handleGoToAdd,
    handleGoToMatricular,
  } = useListaDocentes();
  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      render: (text: string) => <TextCapitalize>{text}</TextCapitalize>,
    },
    {
      title: "Identificación",
      dataIndex: "identificacion",
      key: "identificacion",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Nombramiento",
      dataIndex: "condicion",
      key: "condicion",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Correo Institucional",
      dataIndex: "correoInstitucional",
      key: "correoInstitucional",
      render: (text: string) => <LowerCase>{text}</LowerCase>,
    },
    {
      title: "Teléfono",
      dataIndex: "telefono",
      key: "telefono",
      render: (text: string) => <p>{text}</p>,
    },
    {
      dataIndex: "id",
      key: "id",
      render: (value: any, record: any) => (
        <StyledButton
          variant="link"
          onClick={() => handleGoToMatricular(record)}
        >
          Matricular
        </StyledButton>
      ),
    },
  ];

  return (
    <PageContainer>
      <HeaderContainer>
        <BackButton
          text="Lista de Matriculados"
          link={`/admin/lista-matriculados?grupo=${grupo}&periodo=${periodo}`}
        />
      </HeaderContainer>

      <ButtonsContainer>
        <Button onClick={handleGoToAdd}>Ingresar nuevo docente</Button>
      </ButtonsContainer>
      {typeof dataGrupo !== "undefined" && (
        <>
          <Heading2 style={{ marginBottom: "16px" }}>
            {dataGrupo.nombre}
          </Heading2>
          <Group
            key={dataGrupo.id}
            id={dataGrupo.id}
            nombre={dataGrupo.nombre}
            tipoSede={dataGrupo.tipoSede}
            horario={dataGrupo.horario}
            profesor={dataGrupo.nombreProfesor}
            horaEntrada={dataGrupo.horaEntrada}
            horaSalida={dataGrupo.horaSalida}
            cupo={dataGrupo.cupo}
            displayOptions={false}
            displayName={false}
            cupoDisponible={dataGrupo.cupoDisponible}
          />
        </>
      )}

      <Input
        style={{ width: "300px" }}
        placeholder="Buscar por número de cédula"
        type="number"
        disabled={isFetching}
        onChange={(value: string) => {
          handleSearchChange(value);
        }}
      />
      <>
        <DesktopTable>
          <StyledTable
            columns={columns}
            dataSource={
              filteredDataDocentes.length
                ? filteredDataDocentes
                : dataDocentes?.docenteSet
            }
            loading={{
              spinning: isLoading || isFetching,
              indicator: <Spinner isLoading={isLoading || isFetching} />,
            }}
          />
        </DesktopTable>
      </>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  @media (min-width: 768px) {
    margin-bottom: 45px;
  }
`;

const DesktopTable = styled.div`
  @media (min-width: 1200px) {
    display: block;
  }
`;

const StyledTable = styled(Table)`
  margin-top: 20px;
  td.ant-table-cell * {
    font-size: 18px;
    line-height: 25.2px;
    color: ${(props) => props.theme.colors.blackOne};
  }
`;

const LowerCase = styled.p`
  text-transform: lowercase;
`;

const TextCapitalize = styled.p`
  text-transform: capitalize;
`;

const StyledButton = styled(Button)`
  width: 172px;
  justify-content: end;
  span {
    color: ${(props) => props.theme.colors.lightBlue} !important;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 1200px) {
    top: 175px;
    right: 120px;
    position: absolute;
  }
`;
