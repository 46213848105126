import styled, { css } from "styled-components";
import { Button as antdButton } from "antd";

interface ButtonProps {
  variant?: string;
  width?: string;
  align?: string;
}

const BaseButton = styled(antdButton)<ButtonProps>`
  & {
    background-color: ${(props) => props.theme.colors.buttonBlue};
    color: ${(props) => props.theme.colors.white};
    padding-top: 12px !important;
    padding-bottom: 12px;
    border: none;
    text-align: center;
    border-radius: 4px;
    width: ${(props) => props.width};
    font-weight: 700;
    font-family: "Oxygen", sans-serif;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 46px;

    &:hover {
      line-height: 0;
      border: 2px solid ${(props) => props.theme.colors.buttonBlue};
      span {
        color: ${(props) => props.theme.colors.buttonBlue} !important;
      }
    }

    &:focus {
      background-color: ${(props) => props.theme.colors.buttonBlue};
      color: ${(props) => props.theme.colors.white};
      span {
        color: ${(props) => props.theme.colors.white} !important;
      }
    }

    .anticon {
      margin-right: 10px;
    }

    &&.ant-btn {
      padding-top: 12px !important;
    }

    @media (min-width: 768px) {
      font-size: 18px;
      min-width: 123px;
    }
    ${(props) =>
      props.variant === "secondary" &&
      css`
       &&.ant-btn {
          padding-top: 12px !important;
        }
        color: ${(props) => props.theme.colors.buttonBlue};
        background-color: ${(props) => props.theme.colors.white};
        border: 2px solid ${(props) => props.theme.colors.buttonBlue};
        float: left;
        padding-top: 12px !important;
        line-height: 0;
      `}

    ${(props) =>
      props.variant === "link" &&
      css`
        &&.ant-btn {
          padding-top: 0px !important;
        }
        border: none !important;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        padding: 0;
        color: ${(props) => props.theme.colors.black};
        background-color: ${(props) => props.theme.colors.white};
        box-shadow: none;
        justify-content: start;
        padding-top: 0px !important;
        &:hover {
          border: none;
          color: ${(props) => props.theme.colors.black} !important;
          background-color: transparent;
          span {
            color: ${(props) => props.theme.colors.black} !important;
          }
        }
        &:focus {
          border: none !important;
          background-color: transparent;
          color: ${(props) => props.theme.colors.black} !important;
          span{
            color: ${(props) => props.theme.colors.black} !important;

          }
        }
      `}
      ${(props) =>
      props.variant === "danger" &&
      css`
        &&.ant-btn {
          padding-top: 0px !important;
        }
        border: 2px solid ${(props) => props.theme.colors.red};
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        padding: 0;
        color: ${(props) => props.theme.colors.red} !important;
        background-color: ${(props) => props.theme.colors.white};
        box-shadow: none;
        justify-content: center;
        span {
          color: ${(props) => props.theme.colors.red} !important;
        }
        &:hover {
          border: 2px solid ${(props) => props.theme.colors.red};
          background-color: ${(props) => props.theme.colors.red};
          color: ${(props) => props.theme.colors.white} !important;
          span {
            color: ${(props) => props.theme.colors.white} !important;
          }
        }
        &:focus {
          border: 2px solid ${(props) => props.theme.colors.red};
          background-color: ${(props) => props.theme.colors.white};
          color: ${(props) => props.theme.colors.red} !important;
          span {
            color: ${(props) => props.theme.colors.red} !important;
          }
        }
      `}
  }
`;

export function Button({ ...props }): JSX.Element {
  return <BaseButton {...props} />;
}
