import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import styled from "styled-components";

import { BackButton, Title, HeaderContainer } from "components";
import { CrearGrupoForm } from "components/Forms";
import { useGetGrupo } from "hooks/useRequest";
import { editarGrupo } from "./mutation";
import { Mutation } from "hooks/useMutation";

export default function EditarGrupo() {
  const history = useHistory();
  const { search } = useLocation();

  const mutation = Mutation(editarGrupo);

  const { grupo } = queryString.parse(search);

  const { data, remove } = useGetGrupo({
    //@ts-ignore
    id: parseInt(grupo),
  });

  const initialValues = data
    ? {
        name: data.nombre,
        qty: data.cupo,
        type: data.tipoSede,
        day: data.horario,
        profesor: data.nombreProfesor,
        startTime: moment(data.horaEntrada, "h:mm a"),
        endTime: moment(data.horaSalida, "h:mm a"),
      }
    : null;

  const onFinish = (values: any) => {
    const formattedValues = {
      id: data.id,
      nombre: values.name,
      horario: values.day,
      tipoSede: values.type,
      cupo: values.qty,
      profesor: values.profesor,
      horaEntrada: moment(values.startTime).format("h:mm a"),
      horaSalida: moment(values.endTime).format("h:mm a"),
    };
    /*@ts-ignore*/
    mutation.mutate(formattedValues);
    goBack();
  };

  const goBack = () => {
    history.push({
      pathname: "/admin/periodo-matricula",
      search: `?periodo=${data.periodoMatricula.id}`,
    });
    remove();
  };

  return (
    <PageContainer>
      <HeaderContainer>
        <BackButton text="Inicio" onClick={() => goBack()} link="/admin" />
      </HeaderContainer>
      {data && (
        <>
          <Title title={`Editar ${initialValues?.name}`} />
          <CrearGrupoForm
            initialValues={initialValues}
            onCancel={goBack}
            onFinish={onFinish}
            submitButtonText={"Actualizar Grupo"}
          />
        </>
      )}
    </PageContainer>
  );
}

const PageContainer = styled.div`
  @media (min-width: 768px) {
    margin-bottom: 45px;
    padding-right: 86px;
    padding-left: 86px;
  }
`;
