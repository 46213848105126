import { gql } from "graphql-request";

export const crearDocente = gql`
  mutation editarDocente(
    $id: ID
    $carrera: String
    $condicion: String
    $correoInstitucional: String
    $correoSecundario: String
    $identificacion: String
    $nombre: String
    $sede: Boolean
    $telefono: String
    $tipo: Int
    $unidad: String
    $comprobante: Upload
  ) {
    editarDocente(
      id: $id
      carrera: $carrera
      condicion: $condicion
      correoInstitucional: $correoInstitucional
      correoSecundario: $correoSecundario
      identificacion: $identificacion
      nombre: $nombre
      sede: $sede
      telefono: $telefono
      tipo: $tipo
      unidad: $unidad
      comprobante: $comprobante
    ) {
      docente {
        id
        identificacion
        nombre
      }
    }
  }
`;
