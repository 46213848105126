import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { isAdminAuthenticated } from 'utilites/isAuthenticated';

const PublicAdminRoute = ({ component: Component, path }: RouteProps) => {
  console.log(isAdminAuthenticated())
  if (isAdminAuthenticated()) {
    return <Redirect to="/admin/" />;
  }

  return <Route component={Component} path={path} />;
};

export default PublicAdminRoute;