import { ReactNode } from "react";
import styled from "styled-components";
import Profile from "components/Profile";

interface BaseLayoutProps {
  children: ReactNode;
}

export const HeaderContainer = ({ children }: BaseLayoutProps): JSX.Element => {
  const url = window.location.href;

  const pathname = new URL(url).pathname;
  const isAdmin = pathname.includes("admin");

  const name = isAdmin ? "Administrador" : localStorage.getItem("docente");
  const path = isAdmin ? "admin" : "matricula";

  return (
    <StyledHeaderContainer>
      {children}
      <Profile path={path} name={name} />
    </StyledHeaderContainer>
  );
};

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 40px;
  h3 {
    align-self: flex-start;
  }
  & > div {
    margin-bottom: 20px;
    @media (min-width: 1200px) {
      margin-bottom: 0;
    }
  }
  @media (min-width: 1200px) {
    flex-direction: row;
    margin-bottom: 24px;
  }
`;
