import styled from "styled-components";
import { Modal as antdModal } from "antd";

const BaseModal = styled(antdModal)`
  & {
    .ant-modal-close {
      display: none;
      @media (min-width: 768px) {
        display: block;
        right: 48px;
        top: 33px;
      }
    }
    .ant-modal-close-x {
      width: 10px;
      height: 10px;
    }
    .ant-modal-content {
      padding: 33px 24px;
      border-radius: 10px;
      @media (min-width: 768px) {
        padding: 56px 48px;
      }
    }
    .ant-modal-header {
      padding: 0;
      border: none;
      div {
        font-size: 20px;
        line-height: 28px;
        @media (min-width: 768px) {
          font-size: 28px;
          line-height: 40px;
        }
      }
    }
    .ant-modal-body {
      padding: 24px 0;
      font-size: 16px;
      line-height: 22px;
    }
    .ant-modal-footer {
      border: none;
    }
  }
`;

export function Modal({ ...props }): JSX.Element {
  return <BaseModal {...props} />;
}
