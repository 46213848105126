import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "./theme";
import Layout from "./components/Layout";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import GlobalStyles from "./GlobalStyles";
import {AlertProvider} from './context/AlertContext'
import PrivateRoute from 'components/Routes/Private';
import PrivateAdminRoute from 'components/Routes/PrivateAdmin';
import PublicRoute from 'components/Routes/Public';
import PublicAdminRoute from 'components/Routes/PublicAdmin';

import Login from "./pages/Admin/Login";
import Admin from "./pages/Admin/Admin";
import CrearPeriodo from "./pages/Admin/CrearPeriodo";
import CrearGrupo from "./pages/Admin/CrearGrupo";
import Periodo from "./pages/Admin/Periodo";
import EditarGrupo from "./pages/Admin/EditarGrupo";
import ListaDocentes from "./pages/Admin/ListaDocentes";
import EditarDocente from "./pages/Admin/EditarDocente";
import ListaMatriculados from "./pages/Admin/ListaMatriculados";
import MatricularDocente from "./pages/Admin/MatricularDocente";

import LoginMatricula from "./pages/Matricula/Login";
import Cursos from "./pages/Matricula/Cursos";
import MatricularCurso from "./pages/Matricula/MatricularCurso";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={esES}>
        <AlertProvider>
        <Router>
          <Layout>
            <Switch>
              <PublicAdminRoute exact path="/admin/login" component={Login} />
              <PrivateAdminRoute exact path="/admin" component={Admin} />
              <PrivateAdminRoute exact path="/admin/crear-periodo" component={CrearPeriodo} />
              <PrivateAdminRoute exact path="/admin/periodo-matricula" component={Periodo} />
              <PrivateAdminRoute exact path="/admin/crear-grupo" component={CrearGrupo} />
              <PrivateAdminRoute exact path="/admin/editar-grupo" component={EditarGrupo} />
              <PrivateAdminRoute exact path="/admin/lista-docentes" component={ListaDocentes} />
              <PrivateAdminRoute exact path="/admin/editar-docente" component={EditarDocente} />
              <PrivateAdminRoute exact path="/admin/lista-matriculados" component={ListaMatriculados} />
              <PrivateAdminRoute exact path="/admin/matricular-docente" component={MatricularDocente} />

              <PublicRoute exact path="/matricula/login" component={LoginMatricula} />
              <PrivateRoute exact path="/" component={Cursos} />
              <PrivateRoute exact path="/matricula/matricular-curso" component={MatricularCurso} />

            </Switch>
          </Layout>
        </Router>
        </AlertProvider>
        </ConfigProvider>
      </QueryClientProvider>

      {/*<App />*/}
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
