import styled from "styled-components";
import { Form, Button, Input, Select } from "library";
import { Body2, Body3 } from "components/Typography";
import { useState } from "react";
import { useMatriculaDisponible } from "hooks/useRequest";
import { useHistory } from "react-router-dom";
import {humanLegibleDate, isSystemAvailable} from 'utilites/dateFormat'
import dayjs from 'dayjs'

const Options = [
  { label: "Cédula nacional", value: 1 },
  { label: "Cédula residencia", value: 2 },
  { label: "Asilado político", value: 3 },
  { label: "Pasaporte", value: 4 },
  { label: "Permiso de residencia", value: 5 },
  { label: "Permiso de trabajo extranjeros", value: 6 },
  { label: "Refugiado político", value: 7 },
  { label: "Residente permanante", value: 10 },
  { label: "Residente temporal autorizado para laborar en la U.C.R", value: 11 },
  { label: "Categoría especial docente gestionado por la O.A.I.C.E", value: 12 },
  { label: "Refugiado", value: 13 },
  { label: "Apatrida", value: 14 },
  { label: "Categoría especial docente en trámite", value: 15 },

];

interface FormLoginProps {
  periodo: any;
  dateDiff: number;
  tipoPeriodo: any;
  fechaInicio: any;
  availableHours: any;
}

interface ErrorProps {
  condition: string | null;
  type: string;
  dateDiff: any;
  fechaInicio: any;
}

const formatDate = (date: any, condition: string | null) => {
  const daysToAdd = condition === "INTERINO_MAS" ? 1 : 2;
  return dayjs(date).add(daysToAdd, 'day').format("YYYY-MM-DD")
};

const MensajeError = ({
  condition,
  type,
  dateDiff,
  fechaInicio,
}: ErrorProps) => {
  const fechaMatricula = formatDate(fechaInicio, condition);
  return (
    <ErrorContainer>
      {condition === "Docente no existe en la base de datos" ? (
        <ErrorText>
          Datos incorrectos, por favor revise los datos ingresados o consulte
          con el Departamento de Docencia Universitaria.
        </ErrorText>
      ) : (
        <>
          <ErrorText>No está en su fecha de matrícula.</ErrorText>
          <Body3>
            Su fecha de matrícula es el <strong>{humanLegibleDate(fechaMatricula)}</strong> a
            partir de las 9:00 a.m.
          </Body3>
        </>
      )}
    </ErrorContainer>
  );
};

export default function LoginForm({
  periodo,
  dateDiff,
  tipoPeriodo,
  fechaInicio,
  availableHours,
}: FormLoginProps) {
  const [selectedType, setSelectedType] = useState("");
  const [id, setId] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [condition, setCondition] = useState(null);

  const history = useHistory();

  const { refetch, isLoading} = useMatriculaDisponible({
    periodoMatricula: periodo,
    tipo: parseInt(selectedType),
    identificacion: id.trim(),
  });

  const OnFinish = async (values: any) => {
    /*@ts-ignore*/
      const { data, error } = await refetch();
      if (data) {
        localStorage.setItem("user", data.id);
        localStorage.setItem("docente", data.nombre);
        localStorage.setItem("sede", data.sede);
        localStorage.setItem("periodo", periodo);
        history.push({
          pathname: `/`,
          search: `?periodo=${periodo}`,
        });
      } else {
        /*@ts-ignore*/
        const condition = error.response.errors[0].message;
        setCondition(condition);
        setErrorMessage(true);
      }
  };

  const onIdentificationChange = (value:any) => {
    setId(value.target.value);
    setErrorMessage(false)
  }

  return (
    <div>
      <Body2 style={{ marginBottom: "32px" }}>Ingrese para matricular</Body2>
      <Form
        layout={"vertical"}
        onFinish={(values) => OnFinish(values)}
        name="basic"
      >
        <FormContainer>
          <UserInputContainer>
            <Form.Item
              label="Tipo de Identificación"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Seleccione el tipo de identificación",
                },
              ]}
            >
              <Select
                options={Options}
                onChange={(value: any) => setSelectedType(value)}
              />
            </Form.Item>
          </UserInputContainer>
          <PasswordInputContainer>
            <Form.Item
              label="Número de Identificación"
              name="id"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Input onChange={(value:any)=>onIdentificationChange(value)}/>
            </Form.Item>
          </PasswordInputContainer>
        </FormContainer>
        {errorMessage && (
          <MensajeError
            condition={condition}
            type={tipoPeriodo.id}
            dateDiff={dateDiff}
            fechaInicio={fechaInicio}
          />
        )}
        <Button htmlType="submit" width="100%" loading={isLoading} disabled={isLoading || !isSystemAvailable()}>
          Ingresar
        </Button>
        <StyledButtonLabel>La matrícula está activa de 9 a.m a 5 p.m</StyledButtonLabel>
      </Form>
    </div>
  );
}

const FormContainer = styled.div`
  margin-bottom: 96px;
  label {
    font-size: 12px;
    line-height: 19px;
    font-weight: bold;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 22px;
    }
    @media (min-width: 1200px) {
      line-height: 26px;
    }
  }
  @media (min-width: 1200px) {
    margin-bottom: 48px;
  }
  button {
    text-align: right;
  }
  input {
    display: block;
    width: 100%;
  }
  .ant-form-item-control-input {
    width: 100%;
  }
`;

const UserInputContainer = styled.div`
  margin-bottom: 32px;
`;

const PasswordInputContainer = styled.div`
  margin-bottom: 12px;
  @media (min-width: 1200px) {
    margin-bottom: 16px;
  }
`;

const ErrorContainer = styled.div`
  margin-top: -32px;
  margin-bottom: 20px;
  p {
    color: ${(props) => props.theme.colors.black};
  }
`;

const ErrorText = styled(Body2)`
  color: ${(props) => props.theme.colors.red} !important;
  font-size: 18px;
  text-align: left;
  line-height: 22.4px;
`;


const StyledButtonLabel = styled.p`
  margin-top: 10px;
  font-weight: 400;
`