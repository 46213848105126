import { gql } from "graphql-request";

export const editarGrupo = gql`
  mutation editarGrupo(
    $id: ID!
    $nombre: String!
    $horario: String!
    $tipoSede: Boolean!
    $cupo: Int!
    $profesor: String
    $horaSalida: String
    $horaEntrada: String
  ) {
    editarGrupo(
      id: $id
      nombre: $nombre
      horario: $horario
      tipoSede: $tipoSede
      cupo: $cupo
      nombreProfesor: $profesor
      horaSalida: $horaSalida
      horaEntrada: $horaEntrada
    ) {
      id
      nombre,
      success
    }
  }
`;

