import styled, { useTheme } from "styled-components";
import { Body3 } from "components/Typography";
import { CheckOutlined, ExclamationOutlined } from "@ant-design/icons";

interface AlertsProps {
  title?: string;
  status?: string;
}

enum Status {
  SUCCESS = "success",
  ERROR = "error",
}

export default function Alerts({ status, title }: AlertsProps): JSX.Element {
  const theme = useTheme();
  const successAlert = {
    /* @ts-ignore */
    Icon: () =>  <CheckOutlined style={{ color: theme.colors.white }} />,
    /* @ts-ignore */
    backGroundColor: theme.colors.green,
  };

  const errorAlert = {
    /* @ts-ignore */
    Icon: () => <ExclamationOutlined style={{ color: theme.colors.white }} />,
    /* @ts-ignore */
    backGroundColor: theme.colors.red,
  };

  const { backGroundColor, Icon } =
    Status.SUCCESS === status ? successAlert : errorAlert;

  return (
    <AlertContainer style={{ backgroundColor: backGroundColor }}>
      {/*@ts-ignore*/}
      <Icon />
      {/*@ts-ignore*/}
      <Body3 color={theme.colors.white}>{title}</Body3>
    </AlertContainer>
  );
}

const AlertContainer = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px 25px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  p{
    margin-left: 5px;
  }
  @media (min-width: 768px) {
    padding-left: 60px;
  }
  @media (min-width: 1200px) {
    padding-left: 120px;
  }
`;
