import { gql } from "graphql-request";

export const login = gql`
  mutation tokenAuth(
    $email: String!
    $password: String!
  ) {
    tokenAuth(
        email: $email
        password: $password
    ) {
        success,
        errors,
        unarchiving,
        token,
        unarchiving,
        user {
        id,
        username,
        }
    }
  }
`;

