import styled from "styled-components";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { Tabs, Button, Table } from "library";

import { Title, CustomModal, Spinner, HeaderContainer } from "components";

import { useAdmin } from "./useAdmin";

const { TabPane } = Tabs;

export default function Admin() {
  const {
    firstTypeData,
    secondTypeData,
    isLoading,
    isFetching,
    isModalVisible,
    handleCancel,
    handleEdit,
    handleOkDelete,
    handleDelete,
    handleSelectedTab,
    handleAddNew,
  } = useAdmin();

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Fecha de Inicio",
      dataIndex: "fechaInicio",
      key: "fechaInicio",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Fecha de Fin",
      dataIndex: "fechaFin",
      key: "fechaFin",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Matriculados",
      dataIndex: "matriculados",
      key: "matriculados",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (record: any) => (
        <ActionsList>
          <StyledIconButton
            onClick={() => handleEdit(record)}
            variant="link"
            icon={<EditOutlined />}
          />
          <StyledIconButton
            onClick={() => handleDelete(record)}
            variant="link"
            icon={<DeleteOutlined />}
          />
          <StyledIconButton variant="link" icon={<FileOutlined />} />
        </ActionsList>
      ),
    },
  ];

  return (
    <>
      <HeaderContainer>
        <Title title="Sistema de Gestión Matrícula DEDUN" />
      </HeaderContainer>
      <TabContainer>
        <Tabs
          defaultActiveKey="1"
          centered
          onTabClick={(e) => handleSelectedTab(e)}
        >
          <TabPane tab="Didáctica Universitaria" key="1">
            <DesktopTable>
              <Table
                columns={columns}
                dataSource={firstTypeData}
                loading={{
                  spinning: isLoading || isFetching,
                  indicator: <Spinner isLoading={isLoading || isFetching} />,
                }}
              />
            </DesktopTable>
          </TabPane>
          <TabPane tab="Actualización Docente" key="2">
            <DesktopTable>
              <Table
                columns={columns}
                dataSource={secondTypeData}
                loading={{
                  spinning: isLoading || isFetching,
                  indicator: <Spinner isLoading={isLoading || isFetching} />,
                }}
              />
            </DesktopTable>
          </TabPane>
        </Tabs>
      </TabContainer>
      <StyledButton
        onClick={handleAddNew}
        icon={<PlusOutlined style={{ fontWeight: "bold" }} />}
      >
        Nuevo Periodo
      </StyledButton>
      <CustomModal
        okText="Eliminar"
        cancelText="Cancelar"
        title="Eliminar Periodo"
        text={
          "¿Está seguro que desea eliminar el periodo “Actualización Docente I Ciclo 2021”?"
        }
        visible={isModalVisible}
        onOk={handleOkDelete}
        onCancel={handleCancel}
        variant="danger"
      />
    </>
  );
}

const TabContainer = styled.div`
  margin-top: 24px;
  text-align: center;
  @media (min-width: 768px) {
    margin-top: 48px;
  }
  @media (min-width: 1200px) {
    margin-top: 72px;
  }
`;

const StyledButton = styled(Button)`
  width: 312px;
  margin: 0 auto;
  display: block;
  @media (min-width: 768px) {
    width: 320px;
  }
  @media (min-width: 1200px) {
    width: 282px;
    position: absolute;
    top: 152px;
    right: 120px;
  }
`;

const DesktopTable = styled.div`
  @media (min-width: 1200px) {
    display: block;
  }
`;

const ActionsList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIconButton = styled(Button)`
  width: auto;
  min-width: auto;
  box-shadow: none;
  background: transparent;
  padding: 0 12px;
`;
