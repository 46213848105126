import styled from "styled-components";

export default function TopLogoSection(): JSX.Element {
  return (
    <Container>
      <a target="_blank" href="http://www.ucr.ac.cr/" rel="noreferrer">
        <img src="/logoUcr.png" alt="Logo UCR" />
      </a>
    </Container>
  );
}

const Container = styled.div`
  padding: 16px 40px;
  background-color: ${(props) => props.theme.colors.lightBlue};
  text-align: center;
  img {
    width: 282px;
  }
  @media (min-width: 768px) {
    text-align: left;
    padding: 16px 48px;
  }
  @media (min-width: 1200px) {
    text-align: left;
    padding: 16px 120px;
  }
`;
