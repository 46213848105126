import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { matricularDocente } from "./mutation";
import { Mutation } from "hooks/useMutation";
import { isSystemAvailable } from "utilites/dateFormat";
import { useGetGrupo, useGetDocente, useGetPeriodo } from "hooks/useRequest";

import AlertContext from "context/AlertContext";

export const useMatricularCurso = () => {
  const history = useHistory();
  const { search } = useLocation();
  const mutation = Mutation(matricularDocente);

  const [tipoPeriodo, setTipoPeriodo] = useState(0);
  const alert = useContext(AlertContext);
  const { grupo, periodo } = queryString.parse(search);

  const { data } = useGetGrupo({
    //@ts-ignore
    id: parseInt(grupo),
  });

  const { data: dataPeriodo, isFetched } = useGetPeriodo({
    //@ts-ignore
    id: parseInt(periodo),
  });

  const userId = localStorage.getItem("user");

  const user = useGetDocente({
    //@ts-ignore
    id: parseInt(userId),
  });

  const docente = user?.data;

  const initialValues = docente
    ? {
        name: docente.nombre,
        nombramiento: docente.condicion,
        mail: docente.correoInstitucional,
        secondaryMail: docente.correoSecundario,
        id: docente.identificacion,
        tipoId: docente.tipo,
        carrera: docente.carrera,
        unidad: docente.unidad,
        comprobante: null,
        phone: docente.telefono,
        periodoMatricula: periodo,
        sede: 1,
      }
    : null;

  const handleFinish = (values: any) => {
    const isAvailable = isSystemAvailable();
    if (isAvailable) {
      const formattedValues = {
        //@ts-ignore
        idDocente: docente.id,
        correoSecundario: values.secondaryMail,
        carrera: values.carrera,
        telefono: values.phone,
        comprobante: values.comprobante.file,
        grupo: grupo,
      };
      /*@ts-ignore*/
      mutation.mutate(formattedValues);
    } else {
      /*@ts-ignore*/
      alert.error(
        "La matrícula esta disponible a partir de las 9:00 am y hasta las 5:00 pm"
      );
    }
  };

  const handleGoBack = () => {
    history.push({
      pathname: "/",
      search: `?periodo=${periodo}`,
    });
  };
  //@ts-ignore
  useEffect(() => {
    if (mutation.isSuccess) {
      handleGoBack();
    }
  }, [mutation, history]);

  useEffect(() => {
    if (isFetched) {
      setTipoPeriodo(dataPeriodo?.tipoPeriodo.id);
    }
  }, [dataPeriodo, isFetched]);

  return {
    data,
    tipoPeriodo,
    periodo,
    docente,
    initialValues,
    isSubmitting: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
    handleGoBack,
    handleFinish,
  };
};
