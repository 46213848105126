import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { isAuthenticated } from 'utilites/isAuthenticated';

const PublicRoute = ({ component: Component, path }: RouteProps) => {
  if (isAuthenticated()) {
    return <Redirect to="/" />;
  }

  return <Route component={Component} path={path} />;
};

export default PublicRoute;