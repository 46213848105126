import React, { useState } from "react";
import styled from "styled-components";
import { Form, Button, Input, Row, Col, Select, DatePicker } from "library";
import { isEmpty } from "lodash";
import moment from "moment";
import { Body4 } from "components/Typography";

interface CrearPeriodoFormProps {
  onFinish: any;
  desktopAlign?: boolean;
  submitButtonText?: string;
  initialValues?: any;
  selectedType?: number;
  onCancel?: any;
}

const Options = [
  { label: "Didáctica Universitaria", value: 1 },
  { label: "Actualización Docente", value: 2 },
];

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export const CrearPeriodoForm = ({
  onFinish,
  desktopAlign = false,
  submitButtonText = "Crear Periodo",
  initialValues = {},
  selectedType,
  onCancel
}: CrearPeriodoFormProps) => {
  const [isTypeSelected, setIsTypeSelected] = useState(selectedType);
  const defaultType = initialValues.type ? initialValues.type : selectedType;
  const [isOkButtonDisabled, setDisabled] = useState(initialValues)

  return (
    <FormContainer>
      <Form
        {...formItemLayout}
        onFinish={onFinish}
        name="basic"
        initialValues={initialValues}
        onValuesChange={()=>setDisabled(false)}
      >
        <Row gutter={130}>
          <Col xs={24} lg={desktopAlign ? 24 : 12}>
            <Form.Item
              label="Nombre"
              name="name"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Fecha de consulta a recursos humanos"
              name="consultationDate"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <DatePicker
                placeholder="Seleccionar fecha"
                defaultValue={initialValues.consultationDate}
                disabledDate={(current: any) => {
                  return (
                    moment().add(-1, "days") <= current
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={desktopAlign ? 24 : 12}>
            <Form.Item
              label="Tipo de Matrícula"
              name="type"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Select
                options={Options}
                placeholder="Seleccionar tipo de matrícula"
                onChange={(value: any) => setIsTypeSelected(value)}
                defaultValue={defaultType}
              />
            </Form.Item>
            {(defaultType > 0 || !isEmpty(initialValues)) && (
              <Row gutter={16}>
                <Col xs={12} lg={12}>
                  <Form.Item
                    label="Fecha de Inicio"
                    name="startDate"
                    rules={[{ required: true, message: "Campo requerido" }]}
                  >
                    <DatePicker
                      placeholder="Seleccionar"
                      defaultValue={initialValues.startDate}
                      disabledDate={(current: any) => {
                        return (
                          moment().add(-1, "days") >= current
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} lg={12}>
                  <Form.Item label="Fecha de Fin" name="endDate">
                    <DatePicker
                      placeholder={isTypeSelected === 1 ? "" : "Seleccionar"}
                      disabled={defaultType === 1 || isTypeSelected === 1}
                      defaultValue={initialValues.endDate}
                      disabledDate={(current: any) => {
                        return (
                          moment().add(-1, "days") >= current
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
                <Body4 style={{'color': '#65696A'}}>Los períodos de matrícula están activos en horario de 9 a.m. a 5 p.m. únicamente</Body4>
              </Row>
            )}
            <ButtonContainer>
              <Button onClick={onCancel} variant="secondary">Cancelar</Button>
              <Button disabled={isOkButtonDisabled} htmlType="submit">{submitButtonText}</Button>
            </ButtonContainer>
          </Col>
        </Row>
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  margin-top: 24px;
  .ant-form-item {
    margin-bottom: 32px;
    @media (min-width: 768px) {
      margin-bottom: 45px;
    }
  }
  .ant-form-item-label {
    padding-bottom: 0;
  }
  label {
    font-size: 12px;
    line-height: 19px;
    font-weight: bold;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 22px;
    }
    @media (min-width: 1200px) {
      line-height: 26px;
    }
  }
  @media (min-width: 1200px) {
    margin-bottom: 48px;
    margin-top: 0px;
  }
  button {
    text-align: right;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  button {
    flex: 50%;
    &:first-of-type {
      margin-right: 24px;
    }
  }
  @media (min-width: 1200px) {
    margin-top: 80px;
  }
`;
