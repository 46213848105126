import { gql } from "graphql-request";

export const crearPeriodo = gql`
  mutation crearPeriodoMatricula(
    $nombre: String!
    $tipoPeriodo: Int
    $fechaFin: Date
    $fechaInicio: Date
    $fechaConsulta: Date
  ) {
    crearPeriodoMatricula(
      nombre: $nombre
      tipoPeriodo: $tipoPeriodo
      fechaFin: $fechaFin
      fechaInicio: $fechaInicio
      fechaConsulta: $fechaConsulta
    ) {
      periodoMatricula {
        id
        nombre
        tipoPeriodo {
          id
          nombre
        }
        fechaFin
        fechaInicio
        fechaConsulta
      }
      success
    }
  }
`;
