import styled from "styled-components";
import DedunBlack from "../Icons/DedunBlack";
import { Body3, Heading3 } from "components/Typography";
import Clock from 'react-live-clock';

export default function Header(): JSX.Element {

  return (
    <Container>
      <LogoSection>
        <DedunBlack />
      </LogoSection>
      <ClockSection>
        <Body3>Hora del Sistema</Body3>
        <Heading3>
          {" "}
          <Clock format={'h:mm a'} ticking={true} />
        </Heading3>
      </ClockSection>
    </Container>
  );
}

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(51, 92, 123, 0.15);
  padding: 16px 25px;
  display: flex;
  justify-content: space-between;

  @media (min-width: 768px) {
    padding: 24px 50px;
    align-items: center;
  }

  @media (min-width: 1200px) {
    text-align: left;
    padding: 16px 120px;
  }
`;

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ClockSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
