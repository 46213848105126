import styled from "styled-components";
import { InputNumber as antdInput } from "antd";

const BaseInput = styled(antdInput)`

  & {
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.greyOne};
    width: 100%;
    height: 30px;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    font-family: 'Oxygen', sans-serif;
    padding: 0;
    outline: 0;
    border-radius:0;
    min-width: 1px;
    @media(min-width: 768px){
      font-size: 20px;
      line-height: 28px;
    }
    &:hover,  &:focus{
      border-bottom: 1px solid ${(props) => props.theme.colors.buttonBlue};
      outline: none;
    }
  }
`;

export function InputNumber({ ...props }): JSX.Element {
  return <BaseInput {...props} />;
}
