import { gql } from "graphql-request";

export const desmatricularDocente = gql`
  mutation desmatricularDocente($docente: Int, $grupo: Int) {
    desmatricularDocente(docente: $docente, grupo: $grupo) {
      matriculado {
        docente {
          id
        }
      }
      success
    }
  }
`;
