import { Button, Modal } from "library";
import styled from "styled-components";

interface ModalProps {
  onOk: () => void;
  onCancel: () => void;
  text: string;
  okText: string;
  cancelText: string;
  title: string;
  visible: boolean;
  variant?: string
}

interface CustomButtonProps {
  onOk: () => void;
  onCancel: () => void;
  okText: string;
  cancelText: string;
  variant?: string
}

const CustomButtoms = ({
  onOk,
  onCancel,
  okText,
  cancelText,
  variant = ''
}: CustomButtonProps) => {
  return (
    <>
      <ButtonsContainer>
        <Button variant={variant} onClick={onOk}>
          {okText}
        </Button>
        <Button onClick={onCancel}>{cancelText}</Button>
      </ButtonsContainer>
    </>
  );
};

export const CustomModal = ({
  onOk,
  onCancel,
  text,
  okText,
  cancelText,
  title,
  visible,
  variant = ''
}: ModalProps) => {
  return (
    <>
      <Modal
        title={title}
        visible={visible}
        onCancel={onCancel}
        onOk={onOk}
        footer={[
          <CustomButtoms
            okText={okText}
            cancelText={cancelText}
            onCancel={onCancel}
            onOk={onOk}
            variant={variant}
            
          />,
        ]}
      >
        <p>{text}</p>
      </Modal>
    </>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 24px;
  button{
    min-width: 200px;
  }
`;
