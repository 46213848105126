import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

import styled from "styled-components";
import { CrearPeriodoForm } from "components/Forms";
import { Mutation } from "hooks/useMutation";
import { crearPeriodo } from "./mutation";

import { Title, Spinner, HeaderContainer, BackButton } from "components";

export default function CrearPeriodo() {
  const mutation = Mutation(crearPeriodo);
  const history = useHistory();
  const location = useLocation();

  //@ts-ignore
  const selectedType = location.state.type;

  const onFinish = (values: any) => {
    const endDate = values.endDate
      ? moment(values.endDate).format("YYYY-MM-DD")
      : moment(values.startDate, "YYYY-MM-DD")
          .add(2, "days")
          .format("YYYY-MM-DD");

    const formattedValues = {
      nombre: values.name,
      tipoPeriodo: values.type,
      fechaInicio: moment(values.startDate).format("YYYY-MM-DD"),
      fechaFin: endDate,
      fechaConsulta: moment(values.consultationDate).format("YYYY-MM-DD"),
    };
    /*@ts-ignore*/
    mutation.mutate(formattedValues);
  };

  useEffect(() => {
    if (mutation.isSuccess && mutation.data.data) {
      const id = mutation.data.data.crearPeriodoMatricula.periodoMatricula.id;
      history.push({
        pathname: "/admin/periodo-matricula",
        search: `?periodo=${id}`,
      });
    }
  }, [mutation, history]);

  return (
    <PageContainer>
      <HeaderContainer>
        <BackButton text="Inicio" link="/admin" />
      </HeaderContainer>
      <Title title="Nuevo periodo de matrícula" />
      <CrearPeriodoForm
        onFinish={onFinish}
        selectedType={selectedType}
        initialValues={{ type: parseInt(selectedType) }}
        onCancel={() => {
          history.push({
            pathname: "/admin",
          });
        }}
      />
      {mutation.isLoading && (
        <Spinner isLoading={mutation.isLoading} title="Creando periodo" />
      )}
    </PageContainer>
  );
}

const PageContainer = styled.div`
  @media (min-width: 768px) {
    margin-bottom: 45px;
    padding-right: 86px;
    padding-left: 86px;
  }
`;
