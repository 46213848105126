import { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { Tabs, Button, Table } from "library";
import { Title, CustomModal, Spinner, HeaderContainer } from "components";

import {
  useGetMatriculadosPeriodo,
  useGetGruposMatriculados,
} from "hooks/useRequest";
import queryString from "query-string";
import { desmatricularDocente } from "./mutation";
import { Mutation } from "hooks/useMutation";
import { Body3, Body4 } from "components/Typography";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { isSystemAvailable} from 'utilites/dateFormat'

const { TabPane } = Tabs;

export default function Admin() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedTab, setSelectedTab] = useState("1");
  const [gruposMatriculados, setGruposMatriculados] = useState([]);
  const [gruposDisponibles, setGruposDisponibles] = useState([]);

  const history = useHistory();
  const { search } = useLocation();
  const { periodo } = queryString.parse(search);
  const userId = localStorage.getItem("user");
  const nombradoEnSede = localStorage.getItem("sede");

  const { data, isLoading, refetch, isFetching } = useGetMatriculadosPeriodo({
    //@ts-ignore
    id: parseInt(periodo),
  });

  const {
    data: gruposMatriculadosData,
    refetch: refetchGruposMatriculados,
    isFetched: isFetchedMatriculados,
    isSuccess: isSuccessMatriculados,
  } = useGetGruposMatriculados({
    //@ts-ignore
    id: userId,
  });

  const refetchGrupos = ()=>{
    refetchGruposMatriculados()
    refetch()
  }

  const mutation = Mutation(desmatricularDocente, refetchGrupos);

  useEffect(() => {
    if (mutation.isSuccess && !mutation.isLoading) {
      setModalVisible(false);
    }
  }, [mutation, refetchGruposMatriculados]);

  useEffect(() => {
    //@ts-ignore
    let grupoArray = [];
    if (
      isFetchedMatriculados &&
      isSuccessMatriculados &&
      gruposMatriculadosData
    ) {
      gruposMatriculadosData.map((grupo: any) => grupoArray.push(grupo.grupo));
      //@ts-ignore
      setGruposMatriculados(grupoArray);
    } else {
      setGruposMatriculados([]);
    }
  }, [gruposMatriculadosData, isFetchedMatriculados, isSuccessMatriculados]);


  useEffect(() => {
    const gruposDisponiblesSedes =
      nombradoEnSede === "true"
        ? data?.grupoSet
        : //@ts-ignore
          data?.grupoSet.filter((grupo) => grupo?.tipoSede !== true);
    
    const gruposDisponiblesData = gruposMatriculados.length
      ? gruposDisponiblesSedes.filter(function (grupo: any) {
          return gruposMatriculados.find(function (matriculado: any) {
            return matriculado.id !== grupo.id;
          });
        })
      : gruposDisponiblesSedes;

    setGruposDisponibles(gruposDisponiblesData);
  }, [data, gruposMatriculados, nombradoEnSede]);

  const sharedColumns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Día",
      dataIndex: "horario",
      key: "horario",
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: "Horario",
      dataIndex: "horaEntrada",
      key: "horaEntrada",
      render: (text: string, record: any) => (
        <p>
          {record.horaEntrada} - {record.horaSalida}
        </p>
      ),
    },
  ];

  const cursosDisponiblesColumns = [
    ...sharedColumns,
    {
      title: "",
      dataIndex: "id",
      key: "id",
      align: "right",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "center" }}>
          {record.cupoDisponible > 0 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <StyledButton
                disabled={
                 ( typeof gruposMatriculados != "undefined" && data.tipoPeriodo.id == 1 && gruposMatriculados.length ) || !isSystemAvailable()
                }
                onClick={() =>
                  history.push({
                    pathname: "/matricula/matricular-curso/",
                    search: `?grupo=${record.id}&periodo=${periodo}`,
                  })
                }
              >
                Matricular
              </StyledButton>
            </div>
          ) : (
            <Body3>
              <ExclamationCircleOutlined style={{ marginRight: "16px" }} />
              Cupo lleno
            </Body3>
          )}
        </div>
      ),
    },
  ];

  const cursosMatriculadosColumns = [
    ...sharedColumns,
    {
      title: "",
      dataIndex: "id",
      key: "id",
      align: "right",
      render: (record: any) => (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <StyledDangerButton
            loading={mutation.isLoading}
            disabled={mutation.isLoading}
            variant={"danger"}
            onClick={() => onDelete(record)}
          >
            Desmatricular
          </StyledDangerButton>
        </div>
      ),
    },
  ];

  const onDelete = (id: any) => {
    setModalVisible(true);
    setSelectedId(id);
  };

  const handleOk = () => {
    /*@ts-ignore*/
    mutation.mutate({ docente: userId, grupo: selectedId });
  };

  const handleCancel = () => {
    setModalVisible(false);
    setSelectedId(null);
    refetch();
  };

  return (
    <>
      <CustomModal
        okText="Desmatricular"
        cancelText="Cancelar"
        title="Desmatricular Grupo"
        text={"¿Está seguro que desea desmatricular el grupo?"}
        visible={isModalVisible}
        onOk={() => handleOk()}
        onCancel={handleCancel}
        variant={"secondary"}
      />
      <HeaderContainer>
        <Title title="Sistema de Gestión Matrícula DEDUN" />
      </HeaderContainer>

      <TabContainer>
        <Tabs
          defaultActiveKey={selectedTab}
          centered
          onTabClick={(e) => setSelectedTab(e)}
        >
          <TabPane tab="Grupos Disponibles" key="1">
            <DesktopTable>
              <Table
                columns={cursosDisponiblesColumns}
                dataSource={gruposDisponibles}
                loading={{
                  spinning: isLoading || isFetching,
                  indicator: <Spinner isLoading={isLoading || isFetching} />,
                }}
              />
            </DesktopTable>
          </TabPane>
          <TabPane
            tab={`Grupos Matriculados (${gruposMatriculados?.length})`}
            key="2"
          >
            <DesktopTable>
              <Table
                columns={cursosMatriculadosColumns}
                dataSource={gruposMatriculados}
                scroll={{ x: true }}
                loading={{
                  spinning: isLoading || isFetching,
                  indicator: <Spinner isLoading={isLoading || isFetching} />,
                }}
              />
            </DesktopTable>
          </TabPane>
        </Tabs>
      </TabContainer>
      {typeof gruposMatriculados !== "undefined" &&
      gruposMatriculados.length &&
      data.tipoPeriodo.id == 1 ? (
        <Body4>
          <strong>
            *Únicamente se puede matricular un grupo de la lista por docente.
          </strong>
        </Body4>
      ) : null}
    </>
  );
}

const TabContainer = styled.div`
  margin-top: 24px;
  text-align: center;
  @media (min-width: 768px) {
    margin-top: 48px;
  }
  @media (min-width: 1200px) {
    margin-top: 72px;
  }
`;

const DesktopTable = styled.div`
  overflow-x: scroll;

  @media (min-width: 1200px) {
    display: block;
    
  }
`;

const StyledButton = styled(Button)`
  width: 172px;
  span {
    color: ${(props) => props.theme.colors.white} !important;
  }
`;

const StyledDangerButton = styled(Button)`
  width: 172px;
  span {
    color: ${(props) => props.theme.colors.red} !important;
  }
`;
