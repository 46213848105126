import styled from "styled-components";
import DedunWhite from "../Icons/DedunWhite";
import { Body4 } from "components/Typography";

export default function Footer(): JSX.Element {
  return (
    <Container>
      <LogoAndContact>
        <LogoSection>
            <DedunWhite />
        </LogoSection>
        <ContactSection>
          <Body4 color={'white'}>+506 2511 4775 / +506 2511 3599</Body4>
          <Body4 color={'white'}>docencia.efd@ucr.ac.cr</Body4>
          <Body4>
          <a target="_blank" style={{color: '#0BBBEF'}}href="http://docenciauniversitaria.ucr.ac.cr" rel="noreferrer">Departamento de Docencia Universitaria</a>
          </Body4>
        </ContactSection>
      </LogoAndContact>
      <Divider />
      <CopyrightSection>
        <Body4 color={'white'}>© 2022 Universidad de Costa Rica</Body4>
      </CopyrightSection>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  background: #232729;
  padding: 16px 25px;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    padding: 24px 50px;
  }

  @media (min-width: 1200px) {
    padding: 48px 120px;
  }
`;

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-left: auto;

  @media (min-width: 1200px) { 
    margin: 16px 0;
    align-items: flex-start;
    margin-left: initial;
  }
`;

const LogoAndContact = styled.div`
  display: flex;

  @media (min-width: 1200px) { 
    flex-direction: column;
  }
`;

const Divider = styled.hr`
  background: white;
  margin: 16px 0;
`;

const CopyrightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;