import styled from "styled-components";
import { Tabs as antdTabs } from "antd";

export const Tabs = styled(antdTabs)`
  && .ant-tabs-nav-wrap {
    @media (min-width: 1200px) {
      justify-content: flex-start !important;
    }
  }
  & .ant-tabs-nav-list {
    flex: 1;
    @media (min-width: 1200px) {
      flex: 0;
    }
  }

  .ant-tabs-tab {
    flex: 1;
    justify-content: center;
    @media (min-width: 1200px) {
      flex: 0;
      margin-right: 24px;
    }

    div {
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: ${(props) => props.theme.colors.black} !important;
      @media (min-width: 768px) {
        font-size: 28px;
        line-height: 39px;
      }
    }
  }
  .ant-tabs-tab-active {
    div {
      font-weight: bold;
    }
  }

  .ant-tabs-ink-bar {
    position: absolute;
    background: ${(props) => props.theme.colors.yellow};
    pointer-events: none;
    height: 6px !important;
    bottom: 0;
  }
  .ant-tabs-content-holder {
    padding: 35px 0;
  }
  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }
`;
