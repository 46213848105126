import { createGlobalStyle, css } from "styled-components";
import reset from "styled-reset";

const GlobalStyle = createGlobalStyle`${() => css`
  ${reset}

  :root {
    --column-width: 275px;
    --app-menu-height: 72px;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  strong {
    font-weight: bold;
  }

  /* Prevent iOS from automatically zooming in the website. */
  * {
    text-size-adjust: none;
  }

  body {
    font-size: 17px;
    line-height: 20px;
  }

  html {
    height: 100%;
  }

  body,
  #root {
    min-height: 100vh;
    font-family: 'Oxygen', sans-serif;
    height: 100vh;
  }

  #root {
    width: 100%;
    margin: 0 auto;
  }

`}`;

export default GlobalStyle;
