import styled from "styled-components";

import { Button, Row, Col } from "library";
import {
  ActionList,
  BackButton,
  Title,
  CustomModal,
  Spinner,
  HeaderContainer,
  Group,
  CrearPeriodoForm,
  Overline,
} from "components";
import { usePeriodo } from "containers/Admin/Periodo/usePeriodo";

export default function Periodo() {
  const {
    data,
    initialValues,
    selectedGroup,
    periodo,
    isLoading,
    isLoadingCreatePeriod,
    isRefetching,
    isVisible,
    isUpdateVisible,
    isDeletePeriodoVisible,
    setUpdateVisible,
    setIsDeletePeriodoVisible,
    setIsVisible,
    handleCancel,
    handleOnDeleteGroup,
    handleOnCreateGrupo,
    handleOnDeleteModalVisible,
    handleOnDeletePeriod,
    handleOnCreatePeriod,
    remove,
  } = usePeriodo();

  return isLoading || isRefetching ? (
    <Spinner isLoading={isLoading || isRefetching} />
  ) : data ? (
    <PageContainer>
      <HeaderContainer>
        <BackButton text="Inicio" link="/admin" onClick={remove} />
      </HeaderContainer>
      <PageHeaderContainer>
        <Title title={data.nombre} />
        <ActionList
          onEdit={() => setUpdateVisible(!isUpdateVisible)}
          onDelete={() => setIsDeletePeriodoVisible(!isDeletePeriodoVisible)}
        />
      </PageHeaderContainer>
      <ContentContainer>
        <Row gutter={130} style={{ flex: "1" }}>
          <Col xs={24} xl={12}>
            <FormContainer isVisible={isUpdateVisible}>
              <CrearPeriodoForm
                desktopAlign={true}
                onFinish={handleOnCreatePeriod}
                initialValues={initialValues}
                submitButtonText="Actualizar Periodo"
                selectedType={initialValues?.type}
                onCancel={() => setUpdateVisible(!isUpdateVisible)}
              />
            </FormContainer>
          </Col>
          <Col xs={24} xl={12}>
            <GroupContainer isVisible={isUpdateVisible}>
              <Overline>Grupos</Overline>
              <Separator />

              {data.grupoSet.length ? (
                <GroupDataContainer>
                  {data.grupoSet.map((group: any) => {
                    return (
                      <Group
                        key={group.id}
                        id={group.id}
                        nombre={group.nombre}
                        tipoSede={group.tipoSede}
                        horario={group.horario}
                        profesor={group.nombreProfesor}
                        horaEntrada={group.horaEntrada}
                        horaSalida={group.horaSalida}
                        onDelete={handleOnDeleteModalVisible}
                        cupo={group.cupo}
                        periodo={periodo}
                        cupoDisponible={group.cupoDisponible}
                      />
                    );
                  })}
                </GroupDataContainer>
              ) : (
                <EmptyMessageContainer>
                  <h2>El periodo de matricula no posee grupos</h2>
                </EmptyMessageContainer>
              )}
              <StyledButton onClick={handleOnCreateGrupo} variant="secondary">
                Agregar nuevo grupo
              </StyledButton>
            </GroupContainer>
          </Col>
        </Row>
      </ContentContainer>
      {isLoadingCreatePeriod && (
        <Spinner isLoading={isLoadingCreatePeriod} title="Creando periodo" />
      )}
      {selectedGroup && (
        <CustomModal
          okText="Eliminar"
          cancelText="Cancelar"
          title="Eliminar Grupo"
          //@ts-ignore
          text={`¿Está seguro desea eliminar el ${selectedGroup.nombre}?`}
          visible={isVisible}
          onOk={handleOnDeleteGroup}
          onCancel={() => setIsVisible(false)}
          variant="danger"
        />
      )}
      <CustomModal
        okText="Eliminar"
        cancelText="Cancelar"
        title="Eliminar Periodo"
        text={"¿Está seguro que desea eliminar el periodo de matrícula?"}
        visible={isDeletePeriodoVisible}
        onOk={handleOnDeletePeriod}
        onCancel={handleCancel}
        variant="danger"
      />
    </PageContainer>
  ) : null;
}

const PageContainer = styled.div`
  @media (max-width: 1200px) {
    margin-bottom: 45px;
    padding-right: 86px;
    padding-left: 86px;
  }
`;

const ContentContainer = styled.div`
  margin-top: 32px;
  display: flex;
  @media (min-width: 1200px) {
    flex-direction: row;
    flex: 1;
  }
`;

const FormContainer = styled.div<{ isVisible: boolean }>`
  flex: 1;
  display: ${(props) => (!props.isVisible ? "none" : "block")};
  @media (min-width: 1200px) {
    display: block;
    flex: 1;
  }
`;

const GroupContainer = styled.div<{ isVisible: boolean }>`
  flex: 1;
  display: ${(props) => (!props.isVisible ? "block" : "none")};
  @media (min-width: 1200px) {
    display: block;
  }
`;

const Separator = styled.hr`
  border: 0.5px solid #b1b7ba;
  margin-top: 8px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
  }
`;

const PageHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 80px;
`;

const EmptyMessageContainer = styled.div`
  text-align: center;
  padding: 20px 0;
  @media (min-width: 1200px) {
    text-align: left;
  }
`;

const GroupDataContainer = styled.div`
  padding: 24px 0;
`;
