import { gql } from "graphql-request";

export const crearDocente = gql`
  mutation crearDocente(
    $carrera: String
    $condicion: String
    $correoInstitucional: String
    $correoSecundario: String
    $grupo: Int
    $identificacion: String
    $nombre: String
    $periodoMatricula: Int
    $sede: Boolean
    $telefono: String
    $tipo: Int
    $unidad: String
    $comprobante: Upload
  ) {
    crearDocente(
      carrera: $carrera
      condicion: $condicion
      correoInstitucional: $correoInstitucional
      correoSecundario: $correoSecundario
      grupo: $grupo
      identificacion: $identificacion
      nombre: $nombre
      periodoMatricula: $periodoMatricula
      sede: $sede
      telefono: $telefono
      tipo: $tipo
      unidad: $unidad
      comprobante: $comprobante
    ) {
      docente {
        id
        identificacion
        nombre
      },
      success
    }
  }
`;


export const matricularDocente = gql`
  mutation matricularDocente(
    $idDocente: Int
    $correoSecundario: String
    $carrera: String
    $telefono: String
    $comprobante: Upload
    $grupo: Int
  ) {
    matricularDocente(
      idDocente: $idDocente
      correoSecundario: $correoSecundario
      carrera: $carrera
      telefono: $telefono
      comprobante: $comprobante
      grupo: $grupo
    ) {
      docente {
        id
        identificacion
        nombre
      }
      success
    }
  }
`;