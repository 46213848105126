import { useState } from "react";
import styled from "styled-components";
import {
  Form,
  Button,
  Input,
  Row,
  Col,
  TimePicker,
  Select,
  InputNumber,
  Checkbox,
} from "library";

interface CrearPeriodoFormProps {
  onFinish: any;
  desktopAlign?: boolean;
  onCancel: any;
  initialValues?: any;
  submitButtonText?: string;
}

const Options = [
  { label: "Lunes", value: "Lunes" },
  { label: "Martes", value: "Martes" },
  { label: "Miercoles", value: "Miercoles" },
  { label: "Jueves", value: "Jueves" },
  { label: "Viernes", value: "Viernes" },
  { label: "Sabado", value: "Sabado" },
];

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export function CrearGrupoForm({
  onFinish,
  desktopAlign = false,
  onCancel,
  initialValues = null,
  submitButtonText = "Crear Grupo",
}: CrearPeriodoFormProps) {
  const [type, setType] = useState(initialValues ? initialValues.type : false);

  const onTypeChange = (e: any) => {
    setType(e.target.checked);
  };

  const format = "HH:mm A";

  return (
    <FormContainer>
      <Form
        {...formItemLayout}
        onFinish={(values) => onFinish({ ...values, type: type })}
        name="basic"
        initialValues={initialValues}
      >
        <Row gutter={130}>
          <Col xs={24} lg={desktopAlign ? 24 : 12}>
            <Form.Item
              label="Nombre del Grupo"
              name="name"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Nombre del Profesor"
              name="profesor"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={desktopAlign ? 24 : 12}>
            <Row gutter={32}>
              <Col xs={6}>
                <Form.Item
                  label="Cupos"
                  name="qty"
                  rules={[{ required: true, message: "Campo requerido" }]}
                >
                  <InputNumber type="number" min={1} />
                </Form.Item>
              </Col>
              <Col xs={18}>
                <Form.Item
                  label="Dia de Lecciones"
                  name="day"
                  rules={[{ required: true, message: "Campo requerido" }]}
                >
                  <Select
                    options={Options}
                    placeholder="Seleccionar día de lecciones"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col xs={12}>
                <Form.Item
                  label="Hora de Inicio"
                  name="startTime"
                  rules={[{ required: true, message: "Campo requerido" }]}
                >
                  <TimePicker
                    placeholder="Seleccionar"
                    format={format}
                    use12Hours={true}
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label="Hora de Fin"
                  name="endTime"
                  rules={[{ required: true, message: "Campo requerido" }]}
                >
                  <TimePicker
                    placeholder="Seleccionar"
                    format={format}
                    use12Hours={true}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={32}>
              <Col xs={12}>
                <Checkbox checked={type} onChange={onTypeChange}>
                  Grupo Tipo Sede
                </Checkbox>
              </Col>
            </Row>

            <ButtonContainer>
              <Button onClick={() => onCancel()} variant="secondary">
                Cancelar
              </Button>
              <Button htmlType="submit">{submitButtonText}</Button>
            </ButtonContainer>
          </Col>
        </Row>
      </Form>
    </FormContainer>
  );
}

const FormContainer = styled.div`
  margin-top: 24px;
  .ant-form-item {
    margin-bottom: 32px;
    @media (min-width: 768px) {
      margin-bottom: 45px;
    }
  }
  .ant-form-item-label {
    padding-bottom: 0;
  }
  label {
    font-size: 12px;
    line-height: 19px;
    font-weight: bold;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 22px;
    }
    @media (min-width: 1200px) {
      line-height: 26px;
    }
  }
  @media (min-width: 1200px) {
    margin-bottom: 48px;
  }
  button {
    text-align: right;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  button {
    flex: 50%;
    &:first-of-type {
      margin-right: 24px;
    }
  }
  @media (min-width: 1200px) {
    margin-top: 80px;
  }
`;
