import { useQuery } from "react-query";
import { GraphQLClient, gql } from "graphql-request";

const API_URL = process.env.REACT_APP_API_URL as string;
const graphQLClient = new GraphQLClient(API_URL, {
  //@ts-ignore
  headers: {
    authorization: `JWT ${localStorage.getItem('AUTH_TOKEN')}`,
  },
});

interface Params {
  [key: string]: number | string | null;
}

export function useGetAdministrador(value: Params) {
  return useQuery("tokenAuth", async () => {
    const { tokenAuth } = await graphQLClient.request(
      gql`
        query ($email: String, $password: String) {
          tokenAuth(email: $email, password: $password) {
            success,
            errors,
            unarchiving,
            token,
            unarchiving,
            user {
              id,
              username,
            }
          }
        }
      `,
      value
    );
    return tokenAuth;
  },
    { enabled: false }
  );
}

export function useGetPeriodos() {
  return useQuery("periodosMatricula", async () => {
    const { periodosMatricula } = await graphQLClient.request(gql`
      query {
        periodosMatricula {
          id
          nombre
          fechaInicio
          fechaFin
          tipoPeriodo {
            id
            nombre
          }
        }
      }
    `);
    return periodosMatricula;
  },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true
    },);
}


export function useGetPeriodoDisponible() {
  return useQuery("periodoActivo", async () => {
    const { periodoActivo } = await graphQLClient.request(gql`
      query {
        periodoActivo {
          id
          nombre
          fechaInicio
          fechaFin
          tipoPeriodo {
            id
            nombre
          }
        }
      }
    `);
    return periodoActivo;
  });
}

export function useGetPeriodo(value: Params) {
  return useQuery("periodoMatricula", async () => {
    const { periodoMatricula } = await graphQLClient.request(
      gql`
        query periodoMatricula($id: Int!) {
          periodoMatricula(id: $id) {
            id
            fechaInicio
            fechaFin
            nombre
            tipoPeriodo {
              id
              nombre
            }
            grupoSet {
              id
              nombre
              cupo
              horario
              tipoSede
              nombreProfesor
              horaEntrada
              horaSalida
              cupoDisponible
            }
            fechaConsulta
          }
        }
      `,
      value
    );
    return periodoMatricula;
  }, {
    refetchOnWindowFocus: false
  },);
}

export function useGetGrupo(value: Params) {
  return useQuery("grupo", async () => {
    const { grupo } = await graphQLClient.request(
      gql`
        query grupo($id: Int!) {
          grupo(id: $id) {
            nombre
            id
            horario
            cupo
            cupoDisponible
            tipoSede
            nombreProfesor
            horaEntrada
            horaSalida
            periodoMatricula {
              id
            }
          }
        }
      `,
      value
    );
    return grupo;
  }, {
    refetchOnWindowFocus: false
  });
}

export function useGetMatriculadosGrupo(value: Params) {
  return useQuery("matriculados", async () => {
    const { matriculadosGrupo } = await graphQLClient.request(
      gql`
        query matriculadosGrupo($id: Int!) {
          matriculadosGrupo(id: $id) {
            docente {
              id
              nombre
              identificacion
              correoSecundario
              correoInstitucional
              telefono
              carrera
              unidad
              condicion
              comprobante
            }
          }
        }
      `,
      value
    );
    return matriculadosGrupo;
  },{
    refetchOnWindowFocus: false,
  });
}

export function useGetDocente(value: Params) {
  return useQuery("docente", async () => {
    const { docente } = await graphQLClient.request(
      gql`
        query docente($id: Int!) {
          docente(id: $id) {
            id
            tipo
            nombre
            identificacion
            correoSecundario
            correoInstitucional
            telefono
            carrera
            unidad
            condicion
          }
        }
      `,
      value
    );
    return docente;
  });
}

export function useGetDocenteId(value: Params) {
  return useQuery(
    "docentePorIdentificacion",
    async () => {
      const { docentePorIdentificacion } = await graphQLClient.request(
        gql`
          query docentePorIdentificacion(
            $periodoMatricula: Int!
            $tipo: Int!
            $identificacion: String!
          ) {
            docentePorIdentificacion(
              periodoMatricula: $periodoMatricula
              tipo: $tipo
              identificacion: $identificacion
            ) {
              id
              nombre
              identificacion
              correoSecundario
              correoInstitucional
              telefono
              carrera
              unidad
              condicion
            }
          }
        `,
        value
      );
      return docentePorIdentificacion;
    },
    { enabled: false }
  );
}


export function useMatriculaDisponible(value: Params) {
  return useQuery(
    "matriculaDisponible",
    async () => {
      const { matriculaDisponible } = await graphQLClient.request(
        gql`
          query matriculaDisponible(
            $periodoMatricula: Int!
            $tipo: Int!
            $identificacion: String!
          ) {
            matriculaDisponible(
              periodoMatricula: $periodoMatricula
              tipo: $tipo
              identificacion: $identificacion
            ) {
              id
              nombre
              identificacion
              correoSecundario
              correoInstitucional
              telefono
              carrera
              unidad
              condicion
              sede
            }
          }
        `,
        value
      );
      return matriculaDisponible;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );
}


export function useGetMatriculado(value: Params) {
  return useQuery("matriculado", async () => {
    const { matriculado } = await graphQLClient.request(
      gql`
        query matriculadosGrupo($id: Int!) {
          matriculado(id: $id) {
            docente {
              id
              nombre
              identificacion
              correoSecundario
              correoInstitucional
              telefono
              carrera
              unidad
              condicion
            }
          }
        }
      `,
      value
    );
    return matriculado;
  });
}

export function useGetMatriculadosPeriodo(value: Params) {
  return useQuery("periodoMatricula", async () => {
    const { periodoMatricula } = await graphQLClient.request(
      gql`
        query periodoMatricula($id: Int!) {
          periodoMatricula(id: $id) {
            id
            fechaInicio
            fechaFin
            nombre
            tipoPeriodo {
              id
              nombre
            }
            grupoSet {
              id
              nombre
              cupo
              cupoDisponible
              horario
              tipoSede
              nombreProfesor
              horaEntrada
              horaSalida
            }
            fechaConsulta
          }
        }
      `,
      value
    );
    return periodoMatricula;
  },
    { refetchOnWindowFocus: false }
  );
}


export function useGetGruposMatriculados(value: Params) {
  return useQuery("gruposMatriculados", async () => {
    const { gruposMatriculados } = await graphQLClient.request(
      gql`
        query gruposMatriculados($id: Int!) {
          gruposMatriculados(id: $id) {
            grupo{
              id
              nombre
              cupo
              cupoDisponible
              horario
              tipoSede
              nombreProfesor
              horaEntrada
              horaSalida
            }
          }
        }
      `,
      value
    );
    return gruposMatriculados;
  });
}


export function useGetDocentesPeriodo(value: Params) {
  return useQuery("periodoMatricula", async () => {
    const { periodoMatricula } = await graphQLClient.request(
      gql`
        query periodoMatricula($id: Int!) {
          periodoMatricula(id: $id) {
            id
            tipoPeriodo{
              id
            }
            docenteSet{
              id
              nombre
              identificacion
              correoSecundario
              correoInstitucional
              telefono
              carrera
              unidad
              condicion
              comprobante
              tipo
              sede
            }
            fechaConsulta
          }
        }
      `,
      value
    );
    return periodoMatricula;
  },
    {
      refetchOnWindowFocus: false,
    }
  );
}